import { enableProdMode, StaticProvider } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';
import * as SERVER_SIDE from '@mintplayer/ng-server-side';

import { AppModule } from './app/app.module';
import { environment } from './environments/environment';

if (environment.production)
{
    enableProdMode();
    if (typeof window !== 'undefined') 
    { 
      console.log = () => {};
      console.warn = () => {};
      console.error = () => {};
      console.info = () => {};
    }
}

function bootstrap() {
    const providers: StaticProvider[] = [
      { provide: SERVER_SIDE, useValue: false }
    ];
  
    platformBrowserDynamic(providers).bootstrapModule(AppModule)
    .catch(err => console.error(err));
  };

  if (document.readyState === 'complete') {
    bootstrap();
  } else {
    document.addEventListener('DOMContentLoaded', bootstrap);
  }

// document.addEventListener('DOMContentLoaded', () => {
//     platformBrowserDynamic().bootstrapModule(AppModule)
//         .catch(err => console.error(err));
// });
