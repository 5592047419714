import { Component, Inject, NgZone, OnDestroy, OnInit, PLATFORM_ID, Renderer2 } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { CartService } from './shared/services/cart.service';
import { CompareService } from './shared/services/compare.service';
import { WishlistService } from './shared/services/wishlist.service';
import { NavigationEnd, Router } from '@angular/router';
import { DOCUMENT, isPlatformBrowser, ViewportScroller } from '@angular/common';
import { CurrencyService } from './shared/services/currency.service';
import { debounceTime, takeUntil } from 'rxjs/operators'; 
import { Subject } from 'rxjs';

@Component({
    selector: 'app-root',
    templateUrl: './app.component.html',
    styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit, OnDestroy {
    cookieAccepted:boolean=true;
    private destroy$ = new Subject<void>();
    constructor(
        @Inject(PLATFORM_ID) private platformId: any,
        private router: Router,
        private toastr: ToastrService,
        private cart: CartService,
        private compare: CompareService,
        private wishlist: WishlistService,
        private zone: NgZone,
        private scroller: ViewportScroller,
        private currency: CurrencyService, 
        private renderer: Renderer2, @Inject(DOCUMENT) private _document: any, 
    ) {
        
    }
    ngOnDestroy() {
        this.destroy$.next();
        this.destroy$.complete();
    }

    ngOnInit(): void 
    {  
        if (isPlatformBrowser(this.platformId)) 
        { 
            this.zone.runOutsideAngular(() => {
                this.cart.getMe()
                    .pipe(takeUntil(this.destroy$))
                    .subscribe(res => {
                        this.cart.setGetMe(res);
                });
                this.injectScripts();
                // this.router.events.pipe(filter(event => event instanceof NavigationEnd), first()).subscribe(() => {   }); 
                setTimeout(() => 
                { 
                    if(localStorage.getItem('cookie_accept') === "true"){
                        this.cookieAccepted = true;
                    }
                    else{
                        this.cookieAccepted = false;
                    }
                    try{
                        const cookies = document.cookie.split('; ');
                        const cookie = cookies.find((row) => row.startsWith(`${'me2_security'}=`));
                        var res = cookie ? cookie.split('=')[1] : null;
                        if(res != 'done')
                        { 
                            this.cart.getMe2().pipe(takeUntil(this.destroy$)).subscribe(res =>
                            {
                                if(res && res.length > 0)
                                {  
                                }
                            });
                        } 
                    }
                    catch{}
                    
                }, 9000);
            });
        } 

        this.cart.onCountry.pipe(takeUntil(this.destroy$)).subscribe(res =>
        {
            if(res && res.CurrencyMultiplier > 0)
            {
                this.currency.options = {
                    code: res.CurrencyCode,
                    display: res.CurrencySymbol,
                    digitsInfo: '1.2-2',
                    locale: 'en-US'
                };
            } 
            else{
                var def = this.cart.getCountryInfoFromStorage();
                this.currency.options = {
                    code: def.CurrencyCode,
                    display: def.CurrencySymbol,
                    digitsInfo: '1.2-2',
                    locale: 'en-US'
                }
            }
        });

        this.router.events.pipe(
            takeUntil(this.destroy$),
            debounceTime(100)
            ).subscribe((event) => {
            if (event instanceof NavigationEnd) {
                this.scroller.scrollToPosition([0, 0]);
            }
        });

        this.cart.onAdding$.pipe(takeUntil(this.destroy$)).subscribe(product => 
        {
            if(product && product !== null && product.ID > 0)
            {
                this.toastr.success(`"${product.Name}" added to cart!`);
            }
            else
            {
                this.toastr.error(`"Couldn't add to cart, try again!`);
            }
        }); 

        this.compare.onAdding$.pipe(takeUntil(this.destroy$)).subscribe(product => {
            this.toastr.success(`"${product.Name}" added to compare!`);
        });

        this.wishlist.onAdding$.pipe(takeUntil(this.destroy$)).subscribe(product => 
        {
            this.toastr.success(`"${product.Name}" added to wish list!`);
        });
    }

    cookieAccept()
    {
        if (isPlatformBrowser(this.platformId)) {
            localStorage.setItem('cookie_accept','true');
            this.cookieAccepted = true;
        }
    }

    injectScripts()
    { 
        const gtmScriptTag = this.renderer.createElement('script');
        gtmScriptTag.type = 'text/javascript';
        gtmScriptTag.src = 'https://www.googletagmanager.com/gtag/js?id=G-41Y8CLD8KN';
        this.renderer.appendChild(this._document.body, gtmScriptTag);
        
        
        const gtagInitScript = this.renderer.createElement('script');
        gtagInitScript.type = 'text/javascript';
        gtagInitScript.text = `
            window.dataLayer = window.dataLayer || [];
            function gtag(){dataLayer.push(arguments);}
            gtag('js', new Date());
            gtag('config', 'G-41Y8CLD8KN');
        `;
        this.renderer.appendChild(this._document.body, gtagInitScript);
    }
}  