import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { CountryInfo, GlobalCultureState } from '../interfaces/product';
import { StorageService } from './storage.service';

@Injectable({
    providedIn: 'root'
})
export class StoreService 
{
    address = 'RAJUK Project, Sector 18, Uttara, Dhaka - 1230';
    email = 'izzlifestyle@contact.com';
    phone = ['(800) 060-0730', '(800) 060-0730'];
    hours = '24/7/365';

    get primaryPhone(): string|null {
        return this.phone.length > 0 ? this.phone[0] : null;
    }

    public countryInfo:CountryInfo;

    private baseUrl = environment.apiUrl;
    onCultureDataChange$: BehaviorSubject<any>;
    cultureState: GlobalCultureState = 
	{
		typeInfos: {
			"Account.Type":<any[]> Array(), 
			"System.Country":<any[]> Array(),
			"System.Language":<any[]> Array(),
			"System.WorldRegion":<any[]> Array(), 
			"System.Currency": <any[]>Array()
		},
		culture:{ },
		currentCulture:'en',
		supporterdCultureCodes:<any[]> Array(), 
	} 
    
    constructor(private http:HttpClient, private storage:StorageService)
    { 
        this.onCultureDataChange$ = new BehaviorSubject({});
        this.init(); 
    }

    init()
    {
        var oldState = this.storage.getItem("u_cultureState");

        try
        {
            if(oldState && oldState !== null)
            {
                this.cultureState = JSON.parse(oldState);
                this.onCultureDataChange$.next(this.cultureState); 
            }
            else
            {
                this.getAndInitTypeInfo("en").subscribe(
                {
                    complete:()=>
                    {
            
                    }
                });
            }
        }
        catch
        {
            this.getAndInitTypeInfo("en").subscribe(
            {
                complete:()=>
                {
        
                }
            });
        } 
    }

    getAndInitTypeInfo(culture:string): Observable<any> 
	{
		return this.http.get<any>(this.baseUrl +`/api/Culture/GetTypeInfos/${culture}`, {}).pipe(map((res:any) => 
        { 
            console.log(res);
			 if(res && res.Counts > 0)
			 { 
                let nxtTypeInfos = Object.assign({}, this.cultureState.typeInfos);
                for (let key in nxtTypeInfos) 
                { 
                    nxtTypeInfos[key] = res.Result.filter(f=>f.Type == key);
                    nxtTypeInfos[key] = ((key==='System.Country')|| (key==='System.Language') || (key==='System.WorldRegion') || (key==='System.Currency')?
                    nxtTypeInfos[key].sort((a, b) => (a.Name > b.Name) ? 1 : -1) :nxtTypeInfos[key].sort((a, b) => (a.Order > b.Order) ? 1 : -1))
                }
                this.cultureState.typeInfos = nxtTypeInfos;
                this.cultureState.currentCulture = culture;  
                this.storage.setItem("u_cultureState", JSON.stringify(this.cultureState));

                console.log(this.cultureState); 
                this.getAndInitCultureData("en").subscribe(
                {
                    complete:()=>
                    {
                        
                    }
                });
                this.getAndInitSupportedCulture("en").subscribe(
                {
                    complete:()=>
                    {
                        
                    }
                });
			 }
        }));
	}

    getAndInitCultureData(culture:string): Observable<any> 
	{
		return this.http.get<any>(this.baseUrl +`/api/Culture/GetCultureData/${culture}`, {}).pipe(map((res:any) => 
        {  
            if(res)
            {  
                this.cultureState.culture = res;
                this.onCultureDataChange$.next(this.cultureState);
                this.storage.setItem("u_cultureState", JSON.stringify(this.cultureState));
                console.log(this.cultureState);
            }
        }));
	}
    getAndInitSupportedCulture(culture:string): Observable<any> 
	{
		return this.http.get<any>(this.baseUrl +`/api/Culture/SuportedCultureCodes/${culture}`, {}).pipe(map((res:any) => 
        {  
            if(res)
            {  
                this.cultureState.supporterdCultureCodes = res;
                this.onCultureDataChange$.next(this.cultureState);
                this.storage.setItem("u_cultureState", JSON.stringify(this.cultureState));
                console.log(this.cultureState);
            }
        }));
	} 
}
