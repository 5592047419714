import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';

@Injectable({
   providedIn: 'root'
})
export class StoreListService {

   baseUrl: string = environment.apiUrl;

   constructor(private http: HttpClient) {

   }

   getAllStoreLocation(): Observable<any[]> {
      return this.http.get<any[]>(this.baseUrl + '/api/Store/GetAllStoreLocation');
   }

   getStoreByLoaction(location: string, cat: string, brand: string = ''): Observable<any> {
      return this.http.get<any>(this.baseUrl + `/api/Store/GetStoreByLoaction?location=${location}&cat=${cat || ''}&brand=${brand || ''}`);
   }

   getStoreDetail(slug: string): Observable<any> {
      return this.http.get<any>(this.baseUrl + `/api/Store/GetStoreDetails?slug=${slug}`);
   }

   newStoreRequest(model): Observable<any> {
      return this.http.post<any>(environment.apiUrl + `/api/Store/NewStoreRequest`, model);
   }

   verifyStoreRequest(model): Observable<any> {
      return this.http.post<any>(environment.apiUrl + `/api/Store/VerifyStoreRequest`, model);
   }

   getPendingShops(): Observable<any[]> {
      return this.http.get<any[]>(this.baseUrl + '/api/Store/GetPendingShops');
   }

   takeStoreRequestAction(model): Observable<any> {
      return this.http.post<any>(environment.apiUrl + `/api/Store/VerifyOrRejectShopRequest`, model);
   }
}
