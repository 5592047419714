 import { NavigationLink } from '../app/shared/interfaces/navigation-link';

export const departments: NavigationLink[] =
[
  {
    "label": "Home Decor",
    "url": "/products/home-decor",
    "menu": {
      "type": "menu",
      "items": [
        {
          "label": "Exclusive Collection",
          "url": "/products/home-decor"
        },
        {
          "label": "All Collection",
          "url": "/products/home-decor"
        }
      ]
    }
  },
  {
    "label": "Wall Decor",
    "url": "/products/wall-decor",
    "menu": {
      "type": "menu",
      "items": [
        {
          "label": "Wall Clock",
          "url": "/products/wall-clock"
        },
        {
          "label": "Wall Stickers",
          "url": "/products/wall-sticker"
        },
        {
          "label": "Wall Hanging Items",
          "url": "/products/wall-hanging"
        }
      ]
    }
  },
  { 
    "label": "Car Accessories",
    "url": "/products/car-decor",
    "menu": {
      "type": "menu",
      "items": [
        {
          "label": "Car Air Freshener",
          "url": "/products/car-air-freshener"
        },
        {
          "label": "Car Decor",
          "url": "/products/car-accessories"
        }
      ]
    }
  },
  {
    "label": "Toys",
    "url": "/products/toys",
    "menu": {
      "type": "menu",
      "items": [
        {
          "label": "Learning Toys",
          "url": "/products/learning-toys"
        },
        {
          "label": "All Toys",
          "url": "/products/toys"
        },
        {
          "label": "Car Models",
          "url": "/products/toy-car-models"
        }
      ]
    }
  }, 
  { 
    "label": "Jewelery And Watches",
    "url": "/products/jewelery-and-watches",
    "menu": {
      "type": "menu",
      "items": [
        {
          "label": "Watches",
          "url": "/products/watches"
        },
        {
          "label": "Jewelery And Style",
          "url": "/products/jewelery"
        }
      ]
    }
  },
  { 
    "label": "Gadget And Gears",
    "url": "/products/gadget-and-gears",
    "menu": {
      "type": "menu",
      "items": [
        {
          "label": "Phone Gadgets",
          "url": "/products/phone-gadgets"
        },
        {
          "label": "Laptop And PC Gadgets",
          "url": "/products/laptop-and-pc-gadgets"
        }
      ]
    }
  },
  { 
    "label": "Health And Life",
    "url": "/products/health-and-life",
    "menu": {
      "type": "menu",
      "items": [
        {
          "label": "Hair Style",
          "url": "/products/hair-style"
        },
        {
          "label": "Skin Care",
          "url": "/products/skin-care"
        }
      ]
    }
  }, 
  {
    "label": "Fashion & Clothing",
    "url": "/products/clothing",
    "menu": {
      "type": "menu",
      "items": [
        {
          "label": "Men's Clothing",
          "url": "/products/men-clothing"
        },
        {
          "label": "Women's Clothing",
          "url": "/products/women-clothing"
        }
      ]
    }
  }
]

