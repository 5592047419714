import { Component, OnDestroy, OnInit } from '@angular/core';
import { takeUntil, tap } from 'rxjs/operators';
import { merge, Observable, Subject } from 'rxjs';
import { MobileMenuService } from '../../../../shared/services/mobile-menu.service';
import { mobileMenu } from '../../../../../data/mobile-menu';
import { MobileMenuItem } from '../../../../shared/interfaces/mobile-menu-item';
import { CartService } from 'src/app/shared/services/cart.service';
import { UserService } from 'src/app/shared/services/user.service';
import { CountryInfo, OnlineProduct } from 'src/app/shared/interfaces/product';
import { CurrencyService } from 'src/app/shared/services/currency.service';
import { BlockHeaderGroup } from 'src/app/shared/interfaces/block-header-group';
import { ShopService } from 'src/app/shared/api/shop.service';

interface ProductsCarouselGroup extends BlockHeaderGroup {
    products$: Observable<OnlineProduct[]>;
}

interface ProductsCarouselData {
    abort$: Subject<void>;
    loading: boolean;
    products: OnlineProduct[];
    groups: ProductsCarouselGroup[];
}



@Component({
    selector: 'app-mobile-menu',
    templateUrl: './mobile-menu.component.html',
    styleUrls: ['./mobile-menu.component.scss']
})
export class MobileMenuComponent implements OnDestroy, OnInit {
    private destroy$: Subject<any> = new Subject();
    countryInfo:CountryInfo;
    featuredProducts: ProductsCarouselData;

    languages = [
        {name: 'Bengali', image: 'language-4'},
        {name: 'English', image: 'language-1'},
        {name: 'French',  image: 'language-2'},
        {name: 'German',  image: 'language-3'}, 
        {name: 'Italian', image: 'language-5'}
    ];

    isOpen = false;
    links: MobileMenuItem[] = mobileMenu; 
    constructor(public mobilemenu: MobileMenuService,  
        private cart:CartService, public currencyService: CurrencyService,
        private userService:UserService, public shop:ShopService) 
    { 
        this.cart.onCountry.pipe(takeUntil(this.destroy$)).subscribe(res =>
        {
            if(res && res.CurrencyMultiplier > 0){
                this.countryInfo = res; 
            } 
        }); 
    }

    ngOnInit(): void 
    { 
        this.mobilemenu.isOpen$.pipe(takeUntil(this.destroy$)).subscribe(isOpen => this.isOpen = isOpen);
        // this.mobilemenu.getMobileMenu().subscribe(res => {
        //     this.links = res;
        // })

        this.featuredProducts = {
            abort$: new Subject<void>(),
            loading: false,
            products: [],
            groups: [
                {
                    name: '',
                    current: true,
                    products$: this.shop.getLatestProducts(),
                }
            ],
        };
        this.groupChange(this.featuredProducts, this.featuredProducts.groups[0]);
    }

    groupChange(carousel: ProductsCarouselData, group: BlockHeaderGroup): void {
        carousel.loading = true;
        carousel.abort$.next();

        (group as ProductsCarouselGroup).products$.pipe(
            tap(() => carousel.loading = false),
            takeUntil(merge(this.destroy$, carousel.abort$)),
        ).subscribe(x =>{ carousel.products = x;});
    }

    ngOnDestroy(): void {
        this.destroy$.next();
        this.destroy$.complete();
    }

    onItemClick(event: MobileMenuItem): void {
        if (event.type === 'link') {
            this.mobilemenu.close();
        }

        // if (event.data && event.data.language) {
        //     console.log(event.data.language); // change language
        // }
    }
}
