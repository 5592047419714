import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';

@Injectable({
providedIn: 'root'
})
export class AccountService 
{ 
	constructor(private http: HttpClient) 
	{ 
		
	}

    login(model:any): Observable<any>
	{
         return this.http.post<any>(environment.apiUrl+'/api/Account/Login', model);
	}

	register(model:any): Observable<any>
	{
         return this.http.post<any>(environment.apiUrl+'/api/Account/Register', model);
	}

	registerByShop(model:any): Observable<any>
	{
         return this.http.post<any>(environment.apiUrl+'/api/Account/RegisterByShop', model);
	}

	resetPasswordRequest(model:any): Observable<any>
	{
         return this.http.post<any>(environment.apiUrl+'/api/Account/ResetPasswordRequest', model);
	}

	resetPasswordByUser(model:any): Observable<any>
	{
         return this.http.post<any>(environment.apiUrl+'/api/Account/ResetPasswordByUser', model);
	}

	// register(model:any): Observable<any>
	// {
    //      return this.http.post<any>(environment.apiUrl+'/api/Account/Register', model);
	// }

	contactRequest(model:any): Observable<any>
	{
         return this.http.post<any>(environment.apiUrl+'/api/Contact/ContactRequest', model);
	}

	subscribeRequest(email:string): Observable<any>
	{
         return this.http.post<any>(environment.apiUrl+'/api/Contact/Subscribe/'+email, {});
	}
}
