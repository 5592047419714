import {HttpEvent, HttpHandler, HttpInterceptor, HttpRequest, HttpClient} from '@angular/common/http'; 
import {Observable} from 'rxjs';
import {Inject, Injectable, PLATFORM_ID} from '@angular/core';  
import { UserService } from '../services/user.service';
import { isPlatformBrowser } from '@angular/common';

@Injectable()
export class AuthInterceptor implements HttpInterceptor
{  
    constructor(private userService:UserService,@Inject(PLATFORM_ID) private platformId: any) 
    {
       
    }
  
    intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> 
    {
        console.log('Intercepted!', req);  
        var token = this.userService.utoken;  
        const ipurl = "https://api.ipstack.com/check?access_key=4c87fc734befdeb582c3c1227a33d0cc"; 

        if (req.url === ipurl)
        {
            return next.handle(req);
        }  
        else
        {

            const copiedReq = req.clone({ 
                setHeaders: {
                    Authorization: `Bearer ${token}`,
                    language: 'en',
                    ContentType: 'application/json; charset=utf-8'
                }

            });
            return next.handle(copiedReq); 
        }  
    }
}
