import { AfterViewInit, Component, OnDestroy, TemplateRef, ViewChild } from '@angular/core';
import { QuickviewService } from '../../services/quickview.service';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { IDBProduct, OnlineProduct } from '../../interfaces/product';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { ConfirmActionComponent } from '../confirm-action/confirm-action.component';

@Component({
    selector: 'app-quickview',
    templateUrl: './quickview.component.html',
    styleUrls: ['./quickview.component.scss']
})
export class QuickviewComponent implements AfterViewInit, OnDestroy {
    private destroy$: Subject<void> = new Subject();

    @ViewChild('modal', { read: TemplateRef }) template: TemplateRef<any>;
    @ViewChild('addeditprodmodal', { read: TemplateRef }) addeditprodmodal: TemplateRef<any>;
    @ViewChild('selectColorModal', { read: TemplateRef }) selectColorModal: TemplateRef<any>;
    @ViewChild('shopEnlistForm', { read: TemplateRef }) shopEnlistForm: TemplateRef<any>;
    @ViewChild('confirmAction', { read: TemplateRef }) confirmAction: TemplateRef<any>;
    @ViewChild('testmodal', { read: TemplateRef }) testmodal: TemplateRef<any>;
    
    modalRef: BsModalRef;
    product: IDBProduct; 
    locations = [];
    store:any;
    msg:string;
    uniqeId:string;
    constructor(
        private quickview: QuickviewService,
        private modalService: BsModalService
    ) { }

    ngAfterViewInit(): void 
    { 
        this.quickview.show$.pipe(takeUntil(this.destroy$)).subscribe(product => 
        {
            if (this.modalRef) {
                this.modalRef.hide();
            }

            this.product = product;
            this.modalRef = this.modalService.show(this.template, {class: 'modal-dialog-centered modal-xl'});
        });

        this.quickview.showAddEditProduct$.pipe(takeUntil(this.destroy$)).subscribe(product => 
        {
            if (this.modalRef) 
            {
                this.modalRef.hide();
            }

            this.product = product;
            this.modalRef = this.modalService.show(this.addeditprodmodal, {class: 'modal-dialog-centered modal-xl', backdrop: 'static'});
           
            if (this.modalRef.content != null) 
            {
                this.modalRef.content.onClose.subscribe(result => 
                {
                    console.log('results', result);
                });
            }
            
        }); 

        this.quickview.showShopEnlist$.pipe(takeUntil(this.destroy$)).subscribe(value => 
        { 
            if (this.modalRef) {
                this.modalRef.hide();
            }
            
            if(value){
                this.locations = value.locations;
                this.store = value.store;
            }
 
            this.modalRef = this.modalService.show(this.shopEnlistForm, {class: 'modal-dialog-centered modal-md'});
        });

        this.quickview.showConfirmModal$.pipe(takeUntil(this.destroy$)).subscribe(value => 
        { 
            if (this.modalRef) 
            {
                this.modalRef.hide();
            }  

            if(value){
                this.msg = value.msg;
                this.uniqeId = value.uniqeId
            }

            this.modalRef = this.modalService.show(this.confirmAction, {class: 'modal-dialog-centered modal-md', ignoreBackdropClick:true}); 
        }); 
    } 
    
    ngOnDestroy(): void 
    {
        this.destroy$.next();
        this.destroy$.complete();
    }
}
