<div class="site-footer__widget footer-contacts">
    <div>
        <div style="width: 56px; height: 56px;margin-bottom: 10px;" class="logo-moto">
            <img style="height:100%; width:100%" src='assets/images/logos/izz_56.png' alt="izzlifestyle - Your Reliable Partner in Garment Solutions"/>
        </div>
    </div>
   
    <h4 class="footer-contacts__title">Delight in Every Click!</h4>

    <div class="footer-contacts__text">
        <span class="text-success">izzlifestyle.com</span>, Explore for trendy home decor, unique gifts, and car accessories. Discover high-quality products designed to add style to your space, gift-giving, and car interiors. Shop now for the perfect touch of luxury and design!
    </div>

    <ul class="footer-contacts__contacts">
        <!-- <li><i class="footer-contacts__icon fas fa-globe-americas"></i> {{ store.address }}</li> -->
        <li style="display: flex; align-items: center;">
            <!-- <app-icon size="16" name="envelope" class="footer-contacts__icon"></app-icon> {{ store.email }} -->
        </li>
        <!-- <li><i class="footer-contacts__icon fas fa-mobile-alt"></i> {{ store.phone.join(', ') }}</li> -->
        <!-- <li><i class="footer-contacts__icon far fa-clock"></i> {{ store.hours }}</li> -->
    </ul>
</div>
