import { HttpClient } from '@angular/common/http';
import { Injectable, OnDestroy } from '@angular/core';
import { Observable, Subject, timer } from 'rxjs';
import { map, takeUntil } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { StoreListService } from '../api/store.service';
import { IDBProduct, OnlineProduct } from '../interfaces/product';

@Injectable({
    providedIn: 'root'
})
export class QuickviewService implements OnDestroy {
    baseUrl = environment.apiUrl;
    private destroy$: Subject<void> = new Subject();
    private showSubject$: Subject<IDBProduct> = new Subject();
    private showSubjectAddEdit$: Subject<IDBProduct> = new Subject();
    private showSubjectShopEnlist$: Subject<any> = new Subject();
    private showSubjectConfirmModal$: Subject<any> = new Subject();
    private showSubjectTestModal$: Subject<any> = new Subject(); 
    public confirmActionResultSubject$: Subject<any> = new Subject();

    showShopEnlist$: Observable<any> = this.showSubjectShopEnlist$.pipe(takeUntil(this.destroy$));
    show$: Observable<IDBProduct> = this.showSubject$.pipe(takeUntil(this.destroy$));
    showAddEditProduct$: Observable<IDBProduct> = this.showSubjectAddEdit$.pipe(takeUntil(this.destroy$));
    showConfirmModal$: Observable<any> = this.showSubjectConfirmModal$.pipe(takeUntil(this.destroy$));
    showTestModal$: Observable<any> = this.showSubjectTestModal$.pipe(takeUntil(this.destroy$));

    constructor(private http: HttpClient, private shop: StoreListService) { }

    show(product: OnlineProduct): Observable<void> {
        const params: { [param: string]: string } = {};
        if (product) {
            params.slug = product.Slug;
        }
        return this.http.get<IDBProduct>(this.baseUrl + `/api/Product/GetProductBySlug`, { params }).pipe(map((res: IDBProduct) => {
            this.showSubject$.next(res);
        }));
    }

    showAddEditProduct(product: IDBProduct): Observable<void> {
        return timer(0).pipe(map(() => {
            this.showSubjectAddEdit$.next(product);
        }));
    }

    showShopEnlistForm(store: any = undefined): Observable<void> {
        return this.shop.getAllStoreLocation().pipe(takeUntil(this.destroy$), map((res: any) => {
            this.showSubjectShopEnlist$.next({ locations: res, store: store });
        }));
    }

    showConfirmModal(uniqeId:string, msg: string = undefined): Observable<void> {
        return timer(0).pipe(map(() => {
            this.showSubjectConfirmModal$.next({uniqeId: uniqeId, msg: msg });
        })); 
    }

    showTestModal(): void {
        this.showSubjectTestModal$.next();
    }


    ngOnDestroy(): void {
        this.destroy$.next();
        this.destroy$.complete();
    }
}
