import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { environment } from 'src/environments/environment';   
import { StorageService } from './storage.service';
@Injectable({
providedIn: 'root'
})
export class UserService {

	utoken:string;
	uinfo:any; 
	uemail:string;
	onUserTokenChanged$: BehaviorSubject<string>;
	onUserInfoChanged$: BehaviorSubject<any>;
	onTabChanged$: BehaviorSubject<string>;  
	
	private baseUrl = environment.apiUrl;
	readonly userTypeConst = {shopper:1, shop:2, company:3, individual:4, boss:786} 
	
	constructor(private http:HttpClient, private storage:StorageService) 
	{    
		this.onUserTokenChanged$ = new BehaviorSubject("");
		this.onUserInfoChanged$ = new BehaviorSubject({});
		this.onTabChanged$ = new BehaviorSubject(null);  
		this.init();
	}

	init()
	{  
		this.utoken = this.storage.getItem("cul_culture_tm_stmp_temp"); 
		this.onUserTokenChanged$.next(this.utoken);  
		var uin = this.storage.getItem("cul_locale_tm_stmp_temp"); 

		if(uin)
		{
			try
			{
				this.uinfo = JSON.parse(uin); 
				this.onUserInfoChanged$.next(this.uinfo);
			}
			catch{} 
		}  
		else
		{
			this.uinfo = {};
			this.onUserInfoChanged$.next(this.uinfo);
		}
		console.log(this.utoken);
	}

	setToken(token:string)
	{
		this.utoken = token;
		this.onUserTokenChanged$.next(this.utoken); 
        this.storage.setItem("cul_culture_tm_stmp_temp", this.utoken); 
	}

	setTokenGetInfo(token:string, email:string)
	{
		this.utoken = token;
		this.onUserTokenChanged$.next(this.utoken); 
        this.storage.setItem("cul_culture_tm_stmp_temp", this.utoken); 

		this.getAndInitUserInfo(email).subscribe(
		{
			complete:()=>
			{

			}
		});
	}

	getAndInitUserInfo(email:string): Observable<any> 
	{
		return this.getUserIfoByEmail(email).pipe(map((res:any) => 
        {
			if(res)
			{
				this.uinfo = res;
				this.onUserInfoChanged$.next(this.uinfo);
				this.storage.setItem("cul_locale_tm_stmp_temp", JSON.stringify(this.uinfo));
			}
        }));
	}   

	isLoggedIn():boolean
	{ 
		if(this.uinfo !== null && this.uinfo !== undefined && this.uinfo.SID > 0)
		{
			return true;
		}

		return false;
	}

	userType():number
	{
		
		if(this.uinfo !== null && this.uinfo !== undefined && this.uinfo.SID > 0)
		{
			return this.uinfo.AccountType;
		}

		return 0;
	}

	isNijera():boolean
	{
		if(this.uinfo !== null && this.uinfo !== undefined && this.uinfo.SID > 0)
		{
			if(this.uinfo.AccountType === 786)
			{
				return true;
			}
		}

		return false;
	}

	isNijerShop():boolean
	{
		if(this.uinfo !== null && this.uinfo !== undefined && this.uinfo.SID > 0)
		{ 
			if(this.uinfo.UserRoles !== null && this.uinfo.UserRoles !== undefined && this.uinfo.UserRoles.includes('nijershop'))
			{
				return true;
			}
		}

		return false;
	}

	onLogout()
	{
		this.storage.clearLocal()
		this.init();  
	} 

	newBDGuid() :string
	{
		return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function(c) {
			var r = Math.random() * 16 | 0,
			v = c == 'x' ? r : (r & 0x3 | 0x8);
			return v.toString(16);
		});
	}

	private getUserIfoByEmail(email:string): Observable<any> 
	{  
		const params: {[param: string]: string} = {ue: email};  

		return this.http.get<any>(this.baseUrl +`/api/Account/getmurgioutofanda/`, {params}); 
	} 

	updateUserInfo(model:any): Observable<any> 
	{
		return this.http.post<any>(this.baseUrl +`/api/UserProfile/UpdateUserInfo/`, model);
	}

	getUserAddress(): Observable<any> 
	{
		return this.http.get<any>(this.baseUrl +`/api/UserProfile/GetAddress/`, {});
	}
	getAddressById(id:number): Observable<any> 
	{
		return this.http.get<any>(this.baseUrl +`/api/UserProfile/GetAddressById/${id}`, {});
	}

	addUserAddress(model:any): Observable<any> 
	{
		return this.http.post<any>(this.baseUrl +`/api/UserProfile/AddNewAddress`, model);
	}

	updateUserAddress(model:any): Observable<any> 
	{
		return this.http.post<any>(this.baseUrl +`/api/UserProfile/UpdateAddress`, model);
	} 
}
