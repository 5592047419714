import { Component, Input, OnInit } from '@angular/core';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { Subject } from 'rxjs';
import { QuickviewService } from '../../services/quickview.service';

@Component({
	selector: 'app-confirm-action',
	templateUrl: './confirm-action.component.html',
	styleUrls: ['./confirm-action.component.scss']
})
export class ConfirmActionComponent implements OnInit 
{
	public onClose: Subject<boolean>; 
	@Input() modalRef:BsModalRef;
	@Input() msg: string; 
	@Input() uniqeId: string; 

	constructor(private quickview:QuickviewService) {  } 


	public ngOnInit(): void {
		this.onClose = new Subject(); 
	}

	public onConfirm(): void {
		this.onClose.next(true);
		this.quickview.confirmActionResultSubject$.next({action: true, uniqeId: this.uniqeId});
		this.modalRef.hide();
	}

	public onCancel(): void {
		this.onClose.next(false);
		this.quickview.confirmActionResultSubject$.next({action: false, uniqeId: this.uniqeId});
		this.modalRef.hide();
	} 
}
