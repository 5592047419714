<ng-template #modal>
    <div class="quickview">
        <button class="quickview__close" type="button" appClick (click)="modalRef.hide()">
            <app-icon name="cross-20" size="20"></app-icon>
        </button>

        <app-product [product]="product" layout="quickview"></app-product>
    </div>
</ng-template>

<ng-template #addeditprodmodal>
    <div class="quickview">
        <button class="quickview__close" type="button" appClick (click)="modalRef.hide()">
            <app-icon name="cross-20" size="20"></app-icon>
        </button>  
    </div>
</ng-template> 

<ng-template #shopEnlistForm>
    <div class="shop-enlist-form">
        <button class="quickview__close" type="button" appClick (click)="modalRef.hide()">
            <app-icon name="cross-20" size="20"></app-icon>
        </button>

        <app-shop-and-featuring-request-form [modalRef]="modalRef" [store]="store" [locations]="locations"></app-shop-and-featuring-request-form>  
    </div>
</ng-template> 

<ng-template #confirmAction>
    <div> 
        <app-confirm-action [modalRef]="modalRef" [msg]="msg" [uniqeId]="uniqeId"></app-confirm-action>  
    </div>
</ng-template> 

<ng-template #testmodal>
    <div class="shop-enlist-form">
        <button class="quickview__close" type="button" appClick (click)="modalRef.hide()">
            <app-icon name="cross-20" size="20"></app-icon>
        </button>

       <p>testing</p>
    </div>
</ng-template> 
