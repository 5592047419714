<div class="site-footer">
    <div class="container">
        <div class="site-footer__widgets">
            <div class="row">
                <div class="col-12 col-md-6 col-lg-4">
                    <app-footer-contacts></app-footer-contacts>
                </div>
                <div class="col-6 col-md-3 col-lg-2">
                    <app-footer-links header="Information" [links]="[
                        {label: 'FAQ',              url: '/site/faq'},
                        {label: 'Refund Policy',              url: '/site/refund-policy'},  
                        {label: 'Privacy Policy',        url: '/site/privacy'},
                        {label: 'Terms And Conditions',  url: '/site/terms'}
                    ]"></app-footer-links>
                </div>
                <div class="col-6 col-md-3 col-lg-2">
                    <app-footer-links header="Clothing World!" [links]="[ 
                        {label: 'Why At izzlifestyle',     url: '/site/why-trust-and-choose-izzlifestyle-for-your-shopping'},
                        {label: 'About Us',    url: '/site/about-us'}, 
                        {label: 'Contact Us',            url: '/site/contact-us'},
                        {label: 'Blog',    url: '/blog'}
                    ]"></app-footer-links>
                </div>
                <div class="col-12 col-md-12 col-lg-4">
                    <app-footer-newsletter class="site-footer__widget"></app-footer-newsletter>
                </div> 
            </div>
        </div>

        <div class="site-footer__bottom">
            <div class="site-footer__copyright">
                Powered by <a href="/" rel="canonical" target="_blank">izzlifestyle.com | © {{year}} All Rights Reserved.</a>
            </div>
            <div class="site-footer__payments footer-payments">   
                <app-icon class="p-card" tooltip="Secure Payment Only" style="margin-top: 4px; fill: #0ca4b2; margin-right: -2px;" size="36" name="fi-payment-security-48"></app-icon> 
                <img loading="lazy" class="p-card" src="assets/images/payment/visa.png" alt="secure-payment-method-visa">
                <img loading="lazy" class="p-card" style="padding-bottom: 2px;"src="assets/images/payment/card.png" alt="secure-payment-method-mastercard">
                <img loading="lazy" class="p-card" style="width: 38px;" src="assets/images/payment/american-express.png" alt="secure-payment-method-american-express">
                <img loading="lazy" src="assets/images/payment/paypal.png" alt="secure-payment-method-paypal"> 
            </div>
        </div>
    </div>
    <app-totop></app-totop>
</div> 