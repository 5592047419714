import { Component } from '@angular/core';
import { CurrencyService } from '../../../../shared/services/currency.service';
import { CartService } from 'src/app/shared/services/cart.service';
import { UserService } from 'src/app/shared/services/user.service';
import { CountryInfo } from 'src/app/shared/interfaces/product';

@Component({
    selector: 'app-header-topbar',
    templateUrl: './topbar.component.html',
    styleUrls: ['./topbar.component.scss']
})
export class TopbarComponent {
    countryInfo:CountryInfo;
    languages = [
        {name: 'Bengali', image: 'language-4'},
        {name: 'English', image: 'language-1'},
        {name: 'French',  image: 'language-2'},
        {name: 'German',  image: 'language-3'}, 
        {name: 'Italian', image: 'language-5'}
    ];

    currencies = [
        // {name: '৳ Taka',  url: '', code: 'BDT', symbol: '৳'},
        {name: '€ Euro', url: '', code: 'EUR', symbol: '€',  digitsInfo: '1.2-2', locale: 'en-US'},
        {name: '£ Pound Sterling', url: '', code: 'GBP', symbol: '£',  digitsInfo: '1.2-2', locale: 'en-US'},
        {name: '$ US Dollar',      url: '', code: 'USD', symbol: '$',  digitsInfo: '1.2-2', locale: 'en-US'},
        {name: '$ Canadian Dollar',      url: '', code: 'CAD', symbol: '$',  digitsInfo: '1.2-2', locale: 'en-US'},
        {name: '(RM) Malaysian Ringgit', url: '', code: 'MYR', symbol: 'RM',  digitsInfo: '1.2-2', locale: 'en-US'}
    ];

    isLoggedIn:boolean;

    constructor(
        public currencyService: CurrencyService,
        private cart:CartService, private userService:UserService
    ) 
    { 
        this.cart.onCountry.subscribe(res =>
        {
            if(res && res.CurrencyMultiplier > 0){
                this.countryInfo = res;
                this.setCurrency(this.getCurrencyByCode(res.CurrencyCode))
            } 
        });
        this.isLoggedIn = this.userService.isLoggedIn();
    }

    setCurrency(currency): void 
    {
        this.currencyService.options = 
        {
            code: currency.code,
            display: currency.symbol,
            digitsInfo: currency.digitsInfo,
            locale:currency.locale
        };
    }

    getCurrencyByCode(code) 
    {   
        return this.currencies.find(currency => currency.code === code) || { name: '$ US Dollar', code: 'USD', symbol: '$', digitsInfo: '1.2-2', locale: 'en-US' };
    } 
}
