<app-block-slideshow [withDepartments]="true"></app-block-slideshow>

<app-block-features></app-block-features>
<!-- <app-ad-banner [banner]="adBanner"></app-ad-banner> -->

<app-block-products-carousel
    header="Elevate Your Space with Premium Home Decor, Gifts, and Accessories!"
    layout="grid-4"
    [loading]="featuredProducts.loading"
    [products]="featuredProducts.products"
    [groups]="featuredProducts.groups"
    (groupChange)="groupChange(featuredProducts, $event)"
></app-block-products-carousel>

<!-- <app-ad-banner [banner]="adBanner2"></app-ad-banner> -->
<app-block-banner></app-block-banner>

<app-block-products header="Discover Top-Quality Gifts, and Car Accessories for Every Occasion!" 
    layout="large-first" 
    [products]="columnLatestProducts">
</app-block-products>

<app-block-categories header="Trending Categories" layout="compact"></app-block-categories>

<!-- <app-ad-banner [banner]="adBanner"></app-ad-banner> -->

<app-block-products-carousel
    header="Premium And Luxury Collections"
    layout="horizontal" 
    [rows]="2"
    [loading]="latestProducts.loading"
    [products]="latestProducts.products"
    [groups]="latestProducts.groups"
    (groupChange)="groupChange(latestProducts, $event)"
></app-block-products-carousel> 

<app-block-posts header="Learn about life style" layout="list-sm" [posts]="posts$|async"></app-block-posts>

<app-block-recent-products></app-block-recent-products>
<!-- <app-block-brands></app-block-brands> -->

<!-- <app-block-product-columns>
    <app-block-product-columns-item header="Top Rated Products" [products]="columnTopRated$|async"></app-block-product-columns-item>
    <app-block-product-columns-item header="Latest" [products]="columnSpecialOffers$|async"></app-block-product-columns-item>
    <app-block-product-columns-item header="Most Wanted" [products]="columnBestsellers$|async"></app-block-product-columns-item>
</app-block-product-columns> -->
