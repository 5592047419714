import { Inject, Injectable, OnDestroy, PLATFORM_ID } from '@angular/core';
import { BehaviorSubject, Observable, of, Subject, timer } from 'rxjs'; 
import { map, takeUntil } from 'rxjs/operators';
import { isPlatformBrowser } from '@angular/common';
import { OnlineProduct } from '../interfaces/product';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { UserService } from './user.service';
import { StorageService } from './storage.service';

interface WishlistData {
    items: OnlineProduct[];
}

@Injectable({
    providedIn: 'root'
})
export class WishlistService implements OnDestroy {
    private data: WishlistData = {
        items: []
    };

    private destroy$: Subject<void> = new Subject();
    private itemsSubject$: BehaviorSubject<OnlineProduct[]> = new BehaviorSubject([]);
    private onAddingSubject$: Subject<OnlineProduct> = new Subject();

    readonly items$: Observable<OnlineProduct[]> = this.itemsSubject$.pipe(takeUntil(this.destroy$));
    readonly count$: Observable<number> = this.itemsSubject$.pipe(map(items => items.length));
    readonly onAdding$: Observable<OnlineProduct> = this.onAddingSubject$.asObservable();
    baseUrl:string = environment.apiUrl;
    
    constructor(
        @Inject(PLATFORM_ID)
        private platformId: any,
        private http: HttpClient,
        private storage:StorageService,
        private userService:UserService) 
    { 
    }

    loadWishPub(){
        if (isPlatformBrowser(this.platformId)) 
        {
            this.load();
        }
    }

    add(product: any): Observable<void> 
    { 
        if(this.userService.isLoggedIn())
        {
            return this.AddToWishList(product.PUID).pipe(map(() => 
            {
                this.onAddingSubject$.next(product); 
                const index = this.data.items.findIndex(item => item.ID === product.ID);
    
                if (index === -1) 
                {
                    this.data.items.push(product);
                    this.save();
                }
            }));
        }
        else
        {
            this.onAddingSubject$.next(product); 
            const index = this.data.items.findIndex(item => item.ID === product.ID);

            if (index === -1) 
            {
                this.data.items.push(product);
                this.save();
            }
            return of();
        }
    }

    remove(product: OnlineProduct): Observable<void>
    { 
        if(this.userService.isLoggedIn())
        {
            return this.removeFromWishList(product.ID).pipe(map(() => 
            {
                const index = this.data.items.findIndex(item => item.ID === product.ID); 
                if (index !== -1) 
                {
                    this.data.items.splice(index, 1);
                    this.save();
                }
            }));
        } 
        else
        {
            return timer(500).pipe(map(() => 
            {
                const index = this.data.items.findIndex(item => item.ID === product.ID); 
                if (index !== -1) 
                {
                    this.data.items.splice(index, 1);
                    this.save();
                } 
            }));
        }
    }

    private save(): void 
    {
        this.storage.setItem('wishlistItems', JSON.stringify(this.data.items));
        this.itemsSubject$.next(this.data.items);
    }

    load(): void 
    {
        const items = this.storage.getItem('wishlistItems');
        var idstr = ""; 

        if (items) 
        {
            this.data.items = JSON.parse(items);
            this.itemsSubject$.next(this.data.items);

            var jitems = JSON.parse(items); 
            for(var i = 0; i < jitems.length; i++)
            {
                if(jitems[i] !== null)
                {
                    idstr += jitems[i].ID.toString() + ";";
                } 
            }
        }
        else{
            this.data.items = [];  
            this.save();
        }
 
        if(this.userService.isLoggedIn() === true)
        {
            this.GetAllWishListItems(idstr).subscribe(res => 
            { 
                if(res && res.items.length > 0)
                {
                    this.data.items = res.items;  
                    this.save();
                }
            });
        } 
    }

    GetAllWishListItems(idstr:string): Observable<WishlistData> 
    { 
        const params: {[param: string]: string} = {}; 
        params.strids = idstr;
 
        return this.http.get<WishlistData>(this.baseUrl +`/api/UserProductItem/GetAllWishListItems/${true}`, {params});
    }

    AddToWishList(puid:string): Observable<OnlineProduct> 
    { 
        return this.http.get<OnlineProduct>(this.baseUrl +`/api/UserProductItem/AddToWishList/${puid}/${true}`); 
    }

    removeFromWishList(prodId:number): Observable<boolean> 
    { 
        return this.http.get<boolean>(this.baseUrl +`/api/UserProductItem/RemoveFromWishList/${prodId}/${true}`); 
    }

    ngOnDestroy(): void {
        this.destroy$.next();
        this.destroy$.complete();
    }
}
