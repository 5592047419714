<div class="footer-newsletter">
    <h5 class="footer-newsletter__title">Style Secrets Unveiled - Get Insider Access!</h5>
    <div class="footer-newsletter__text">
        Be the First to Know: Subscribe to IzzLifestyle's Insider Updates for Exclusive Home Decor, Gift Ideas, Car Accessories and More!
    </div>

    <form action="" class="footer-newsletter__form">
        <label class="sr-only" for="footer-newsletter-address">Email Address</label>
        <input [formControl]="emailControl" type="text" class="footer-newsletter__form-input form-control" id="footer-newsletter-address"
            placeholder="Email Address..."> 
        <button  type="button" class="footer-newsletter__form-button btn btn-primary"
        [ngClass]="{'btn-loading': calling}" (click)="submit();$event.preventDefault()">Subscribe</button>
    </form>
    <div class="text-danger" *ngIf="emailControl.hasError('required')&& emailControl.touched"><small>Email is required</small></div>
    <div class="text-danger" *ngIf="invalidEmail && !emailControl.hasError('required')"><small>Email is invalid</small></div>

    <div class="footer-newsletter__text footer-newsletter__text--social">
        Follow us on social networks
    </div>

    <app-social-links class="footer-newsletter__social-links" shape="circle"></app-social-links>
</div>
