import { Component} from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { DropcartType } from '../../modules/header/components/dropcart/dropcart.component';
import { RootService } from 'src/app/shared/services/root.service'; 

@Component({
    selector: 'app-main',
    templateUrl: './root.component.html',
    styleUrls: ['./root.component.scss']
})
export class RootComponent{
    headerLayout: 'classic'|'compact';
    dropcartType: DropcartType;
    inserted:boolean = false; 
    constructor(
        public route: ActivatedRoute,
        public root:RootService
    ) 
    {
        this.route.data.subscribe(data => {
            this.headerLayout = data.headerLayout;
            this.dropcartType = data.dropcartType || 'dropdown';
        }); 
        if(this.inserted == false){
            this.root.insertWebsiteSchema();
            this.root.insertFAQSchema();
            this.root.insertSocialSchema();
            this.inserted = true;
        }
        
    }  
}
