import { Component, Inject, Input, PLATFORM_ID } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { DirectionService } from '../../../shared/services/direction.service';
import { environment } from 'src/environments/environment';
import { isPlatformServer } from '@angular/common';

@Component({
    selector: 'app-block-slideshow',
    templateUrl: './block-slideshow.component.html',
    styleUrls: ['./block-slideshow.component.scss']
})
export class BlockSlideshowComponent {
    @Input() withDepartments = false;

    options = {
        nav: false,
        dots: true,
        dotsEach:1, 
        rewind:true,
        autoplay:environment.production,
        autoplayTimeout:10000,
        autoplayHoverPause:true,
        lazyLoad:true,
        responsive: {
            0: {items: 1}
        },
        rtl: this.direction.isRTL()
    }; 
    slideOne:any;
    isServer:boolean = false;

    slides = [ 
        {
            title: "Best Deals For Black Friday, Don't Miss Out, Buy New Home Items, Gadgets, Gifts and Toys at Best Price, Free Shipping!",
            text: 'Transform Your Home with Unique Gift Ideas! Up-To 70% Discount And Fress Shipping For All Purchase',
            image_classic: 'assets/images/slides/black_friday-1.webp',
            image_full: 'assets/images/slides/black_friday-1.webp',
            image_mobile: 'assets/images/slides/black_friday-1.webp', 
            background:'linear-gradient(to right, #191516, #6C0614, #463D40)',
            link:"/products/",
            iswhite:true
        },  
        {
            title: 'Transform Your Home with Unique Gift Ideas! Buy Now Home Items, Gadgets, Gifts and Toys at Best Price, Free Shipping!',
            text: '',
            image_classic: 'assets/images/slides/home-decor-2.webp',
            image_full: 'assets/images/slides/home-decor-2.webp',
            image_mobile: 'assets/images/slides/home-decor-2.webp',
            background:'linear-gradient(to right, #D8DCE5, #AFBDCA, #60ACC6)',
            link:"/products/home-decor",
            iswhite:true
        }
    ];
    

    slideBackgroundColor:string = this.slides[0].background

    constructor(
        public sanitizer: DomSanitizer,
        private direction: DirectionService,
        @Inject(PLATFORM_ID) private platformId: any,
    ) { this.slideOne = this.slides[0]; this.isServer = isPlatformServer(this.platformId);}


    caroTranslated(e:any)
    {
        console.log("translate");
        console.log(e);
    }

    caroChanged(e:any)
    {
        console.log("change");
        console.log(e);
        this.slideBackgroundColor = this.slides[e.startPosition].background;
    }
}
