import { Component, Input, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { ToastrService } from 'ngx-toastr';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { CategoryService } from '../../api/category.service';
import { StoreListService } from '../../api/store.service';
import { Brand } from '../../interfaces/brand';

@Component({
	selector: 'app-shop-and-featuring-request-form',
	templateUrl: './enlisting-request-form.component.html',
	styleUrls: ['./enlisting-request-form.component.scss']
})
export class ShopAndFeaturingRequestFormComponent implements OnInit {

	@Input() modalRef:BsModalRef;
	@Input() locations: any[];
	@Input() store:any;

	private destroy$: Subject<void> = new Subject();
	brands: Brand[] = [];
	filteredBrands: string[] = [];
	selectedBrands: string[] = [];
	categories = [{ name: 'Smartphone, Smartwatch', val: 'smartphone' }, { name: 'Refrigaretor, Freezer, TV', val: 'refrigaretor' }, { name: 'Bike', val: 'bike' }]
	invalidEmail:boolean;
	calling:boolean;

	constructor(private formBuilder: FormBuilder, private catservice:CategoryService,
		private toastr: ToastrService, private storeSer:StoreListService) { }

	registerForm: FormGroup = this.formBuilder.group({
		email: new FormControl('', [Validators.required, Validators.email]),
		name: new FormControl('', [Validators.required]),
		phone: new FormControl('', Validators.required),
		storeName: new FormControl('', Validators.required),
		storeAddress: new FormControl('', Validators.required),
		storeLocation: new FormControl('dhaka', Validators.required),
		category: new FormControl('smartphone', Validators.required),
		brand: new FormControl(''),
	});

	verificationForm: FormGroup = this.formBuilder.group({
		email: new FormControl('', [Validators.required, Validators.email]),
		name: new FormControl('', [Validators.required]),
		phone: new FormControl('', Validators.required),
		storeName: new FormControl('', Validators.required),
		storeAddress: new FormControl('', Validators.required),
		storeLocation: new FormControl('dhaka', Validators.required)
	});

	ngOnInit(): void {
		if(this.store){
			this.verificationForm.get("storeAddress").setValue(this.store.address)
			this.verificationForm.get("storeName").setValue(this.store.name)
			this.verificationForm.get("storeLocation").setValue(this.store.locationSlug)
		}
		this.toastr.toastrConfig.preventDuplicates =  true;

		this.catservice.getAllBrands().pipe(takeUntil(this.destroy$)).subscribe(res => {
			this.brands = res;

			this.brands.forEach(b => {
				this.filteredBrands.push(b.name)
			})
		});
	} 

	get f() { return this.registerForm.controls; }

	onSubmitRegister(event: any): void {
		event.preventDefault();
		console.log(this.registerForm.valid);
		this.registerForm.get('email').markAsTouched()
		this.registerForm.get('name').markAsTouched()
		this.registerForm.get('phone').markAsTouched()
		this.registerForm.get('storeName').markAsTouched()
		this.registerForm.get('storeAddress').markAsTouched()

		this.registerForm.get('email').setValue(this.registerForm.value.email.trim())
		this.registerForm.get('name').setValue(this.registerForm.value.name.trim())
		this.registerForm.get('phone').setValue(this.registerForm.value.phone.trim())
		this.registerForm.get('storeAddress').setValue(this.registerForm.value.storeAddress.trim())
		this.registerForm.get('storeName').setValue(this.registerForm.value.storeName.trim())

		if (this.registerForm.get('email').hasError("required")) {
			return
		}
		if (this.registerForm.get('name').hasError("required")) {
			return
		}
		if (this.registerForm.get('storeName').hasError("required")) {
			return
		}
		if (this.registerForm.get('storeAddress').hasError("required")) {
			return
		}
		if (this.registerForm.get('storeLocation').hasError("required")) {
			return
		}
		if (this.registerForm.get('phone').hasError("required")) {
			return
		}
		if (this.registerForm.get('category').hasError("required")) {
			return
		}

		var regexp = new RegExp(/^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/);
		var x =  regexp.test(this.registerForm.value.email);
 
		if(x === false){
			this.invalidEmail = true;
			return;
		}
		else{this.invalidEmail = false;} 
		
		if (this.selectedBrands.length < 1) {
			this.toastr.error("You must choose a brand!");
			return
		}
		

		var model: any = {};
		model.Email = this.registerForm.value.email;
		model.Name = this.registerForm.value.name;
		model.Phone = this.registerForm.value.phone;
		model.StoreLocation = this.registerForm.value.storeLocation;
		model.StoreAddress = this.registerForm.value.storeAddress;
		model.StoreName = this.registerForm.value.storeName;
		model.Category = this.registerForm.value.category;
		model.Brands = this.selectedBrands.join(';');
		this.calling = true;
		this.storeSer.newStoreRequest(model).pipe(takeUntil(this.destroy$)).subscribe(res => {
			if(res === 1){
				this.toastr.warning("A showroom with this information is already exist, use verification option to verify that shop is your.", "Warning",{
					timeOut: 10000, 
				});
			}
			else if(res === false){
				this.toastr.error("Something went wrong try again or later!","Error!");
			}
			else if(res === true){
				this.toastr.success("Thank you! We have recieved your request, one of our representative will review and accept or decline your request!",
				"Request Successful!",{
					timeOut: 10000
				});
				this.modalRef.hide(); 
			}
			this.calling = false;
        }, error=>{
			this.toastr.error("Something went wrong try again or later!","Error!");
			this.calling = false;
		});
	}

	typeaheadOnSelect(e) {

		if (!this.selectedBrands.includes(e.value)) {
			this.selectedBrands.push(e.value);
		}
		else{
			this.toastr.warning("This Brand Already Added!","Duplicate!");
		}

		console.log(this.selectedBrands);
		this.registerForm.get('brand').setValue('');
	}

	removeBrand(br) {
		this.selectedBrands = this.selectedBrands.filter(b => b != br);
	}

	onSubmitVerify(event: any): void { 
		event.preventDefault(); 
		this.verificationForm.get('email').markAsTouched()
		this.verificationForm.get('name').markAsTouched()
		this.verificationForm.get('phone').markAsTouched() 

		this.verificationForm.get('email').setValue(this.verificationForm.value.email.trim())
		this.verificationForm.get('name').setValue(this.verificationForm.value.name.trim())
		this.verificationForm.get('phone').setValue(this.verificationForm.value.phone.trim()) 

		if (this.verificationForm.get('email').hasError("required")) {
			return
		}
		if (this.verificationForm.get('name').hasError("required")) {
			return
		} 
		if (this.verificationForm.get('phone').hasError("required")) {
			return
		} 

		var regexp = new RegExp(/^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/);
		var x =  regexp.test(this.verificationForm.value.email);
 
		if(x === false){
			this.invalidEmail = true;
			return;
		}
		else{this.invalidEmail = false;}  

		var model: any = {};
		model.Email = this.verificationForm.value.email;
		model.Name = this.verificationForm.value.name;
		model.Phone = this.verificationForm.value.phone;
		model.StoreId = this.store.id

		this.calling = true;
		this.storeSer.verifyStoreRequest(model).pipe(takeUntil(this.destroy$)).subscribe(res => {
			if(res === 3){
				this.toastr.warning("We already have a request pending for this shop, please wait until we reach you, Thank you!", "Pending Verification",{
					timeOut: 10000, 
				});
			}
			else if(res === 0){
				this.toastr.error("Something went wrong try again or later!","Error!");
			}
			else if(res === 1){
				this.toastr.success("Thank you! We have recieved your verification request, one of our representative will review and accept or decline your request!",
				"Request Successful!",{
					timeOut: 10000
				});
				this.modalRef.hide(); 
			}
			this.calling = false;
        }, error=>{
			this.toastr.error("Something went wrong try again or later!","Error!");
			this.calling = false;
		});
	}
}
