import { NavigationLink } from '../app/shared/interfaces/navigation-link';

export const navigation: NavigationLink[] = [
    {label: "Hot Products", url: '/products/home-decor/toprated', 
    menu: {
        type: 'menu',
        items: [
            {label: "Home Items", url: '/products/home-decor'},
            {label: "Accessories", url: '/products/accessories'}, 
            {label: "Fashion & Style", url: '/products/clothing'}, 
        ]
    }
    },
    {label: 'Latest', url: '/products/'},
    {label: 'Hot Sales', url: '/products/car-decor'}, 
    {label: 'Kids', url: '/products/toys'}, 
    {label: 'Home Improvement', url: '/products/home-decor', external: true}
];
