import { Inject, Injectable, OnDestroy, PLATFORM_ID } from '@angular/core'; 
import { BehaviorSubject, Observable, Subject, timer } from 'rxjs';
import { map, takeUntil } from 'rxjs/operators';
import { isPlatformBrowser } from '@angular/common';
import { IDBProduct, OnlineProduct } from '../interfaces/product';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { UserService } from './user.service';
import { StorageService } from './storage.service';

interface CompareData {
    items: IDBProduct[];
    quantity:number;
}

@Injectable({
    providedIn: 'root' 
})
export class CompareService implements OnDestroy {
    private data: CompareData = {
        items: [],
        quantity:0
    };

    private baseUrl = environment.apiUrl;
    private destroy$: Subject<void> = new Subject();
    private itemsSubject$: BehaviorSubject<IDBProduct[]> = new BehaviorSubject([]);
    private onAddingSubject$: Subject<IDBProduct> = new Subject();
    private quantitySubject$: BehaviorSubject<number> = new BehaviorSubject(this.data.quantity);

    readonly quantity$: Observable<number> = this.quantitySubject$.asObservable();
    readonly items$: Observable<IDBProduct[]> = this.itemsSubject$.pipe(takeUntil(this.destroy$));
    readonly onAdding$: Observable<IDBProduct> = this.onAddingSubject$.asObservable();

    constructor(
        @Inject(PLATFORM_ID)
        private platformId: any,
        private http:HttpClient,
        private storage:StorageService,
        private user:UserService
    ) {
        if (isPlatformBrowser(this.platformId)) {
            this.load();
        }
    }

    add(product: any): Observable<void> 
    {
        if(this.user.isLoggedIn() === true)
        {
            return this.AddToCompare(product.ID).pipe(map(() => 
            {
                this.onAddingSubject$.next(product); 
                const index = this.data.items.findIndex(item => item.ID === product.ID);
    
                if (index === -1) 
                {
                    this.data.items.push(product);
                    this.save();
                }
            }));
        } 
        else
        {
            return timer(500).pipe(map(() => 
            {
                this.onAddingSubject$.next(product); 
                const index = this.data.items.findIndex(item => item.ID === product.ID);
    
                if (index === -1) 
                {
                    this.data.items.push(product);
                    this.save();
                }
            }));
        }
    }

    remove(product: IDBProduct): Observable<void> 
    {
        if(this.user.isLoggedIn() === true)
        {
            return this.removeFromCompare(product.ID).pipe(map(() => {
                const index = this.data.items.findIndex(item => item.ID === product.ID);
    
                if (index !== -1) {
                    this.data.items.splice(index, 1);
                    this.save();
                }
            }));
        }
        else
        {
            return timer(300).pipe(map(() => 
            {
                const index = this.data.items.findIndex(item => item.ID === product.ID); 
                if (index !== -1) 
                {
                    this.data.items.splice(index, 1);
                    this.save();
                } 
            }));
        }
    }

    private save(): void 
    {
        this.storage.setItem('compareItems', JSON.stringify(this.data.items)); 
        this.itemsSubject$.next(this.data.items);
        this.quantitySubject$.next(this.data.items.length);
    }

    load(): void 
    {
        const items = this.storage.getItem('compareItems'); 
        var idstr = ""; 

        if (items) 
        {
            this.data.items = JSON.parse(items);
            this.itemsSubject$.next(this.data.items);
            this.quantitySubject$.next(this.data.items.length);
            
            try{
                var jitems = JSON.parse(items); 
                for(var i = 0; i < jitems.length; i++)
                {
                    idstr += jitems[i].ID.toString() + ";";
                }
            }
            catch{} 
        }
        else{
            this.data.items = [];  
            this.save();
        }

        if(this.user.isLoggedIn() === true)
        {
            // this.GetAllCompareItems(idstr).subscribe(res => 
            //     { 
            //         if(res && res.items.length > 0)
            //         {
            //             this.data.items = res.items;  
            //             this.save();
            //         }
            //     });
        }
        
    }

    GetAllCompareItems(idstr:string): Observable<CompareData> 
    { 
        const params: {[param: string]: string} = {}; 
        params.strids = idstr;
 
        return this.http.get<CompareData>(this.baseUrl +`/api/UserProductItem/GetAllCompareItems/${false}`, {params});
    }

    AddToCompare(prodId:number): Observable<OnlineProduct> 
    { 
        return this.http.get<OnlineProduct>(this.baseUrl +`/api/UserProductItem/AddToWishList/${prodId}/${false}`); 
    }

    removeFromCompare(prodId:number): Observable<boolean> 
    { 
        return this.http.get<boolean>(this.baseUrl +`/api/UserProductItem/RemoveFromWishList/${prodId}/${false}`); 
    }

    ngOnDestroy(): void {
        this.destroy$.next();
        this.destroy$.complete();
    }
}
