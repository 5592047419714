<div class="account-menu">

    <form class="account-menu__form" [formGroup]="loginFormMenu" (ngSubmit)="onSubmit($event)" *ngIf="isLoggedIn == false">
        <div class="account-menu__form-title">Log In to Your Account</div>
        <div class="form-group">
            <label for="header-signin-email" class="sr-only">Email address</label>
            <input id="header-signin-email" type="email" class="form-control form-control-sm" placeholder="Email address" formControlName="email">
        </div>
        <div class="form-group">
            <label for="header-signin-password" class="sr-only">Password</label>
            <div class="account-menu__form-forgot">
                <input id="header-signin-password" type="password" class="form-control form-control-sm" placeholder="Password" formControlName="password">
                <a href="/" class="account-menu__form-forgot-link">Forgot?</a>
            </div>
        </div>
        <div class="form-group account-menu__form-button">
            <button type="submit" class="btn btn-primary btn-sm">Login</button>
        </div>
        <div class="account-menu__form-link"><a routerLink="/account/login" (click)="closeMenu.emit()">Create An Account</a></div>
    </form>

    <div class="account-menu__divider"></div>
    <a routerLink="/account/{{defaultRoute}}" class="account-menu__user" (click)="closeMenu.emit()" *ngIf="isLoggedIn == true">
        <div class="account-menu__user-avatar">
            <img src="assets/images/avatars/avatar-3.jpg" alt="">
        </div>
        <div class="account-menu__user-info">
            <div class="account-menu__user-name">{{userInfo?.FirstName}} {{userInfo?.LastName}}</div>
            <div class="account-menu__user-email">{{userInfo?.Email}}</div>
        </div>
    </a>
    <div class="account-menu__divider"></div>
    <ul class="account-menu__links" *ngIf="isLoggedIn == true">
        <li><a routerLink="/account/myaccount" (click)="closeMenu.emit()">My Profile</a></li>
        <li><a routerLink="/account/orders" (click)="closeMenu.emit()">Order History</a></li> 
    </ul>
    <div class="account-menu__divider" *ngIf="isLoggedIn == true"></div>
    <ul class="account-menu__links" *ngIf="isLoggedIn == true">
        <li><a (click)="logout()">Logout</a></li>
    </ul>
</div>
