
<!-- <div class="first-cont"> 
    <img src="assets/images/pngs/smartphone-shop-128.png" />

    <h1>
        Want To Increase Your Sell And Online Presence? 
    </h1>
    <p>
        Enlist your store and showroom with cheapdam.com to increase your online cutomers and potentiality or Feature your products on cheapdam.com to boost your sell Right Now!
    </p>

    <div class="btn-col">
        <button class="btn btn-primary btn-md">Enlist Shop</button>
        <button class="btn btn-secondary btn-md">Feature</button>
    </div> 
</div> -->

<div class="form-cont" *ngIf="!store">
    <div class="header">
        <img src="assets/images/pngs/shop-request-verify-64.png" />
        <div>
            <h5>Enlistment Request</h5>
            <p>Enlisting your shop with <span style="color: #47991f;">cheapdam.com</span> will boost online sales and presence!</p>
        </div>
    </div>

    <div class="card-body"> 
        <form [formGroup]="registerForm" (ngSubmit)="onSubmitRegister($event)">
            <div class="form-group"> 
                <input type="text" class="form-control" placeholder="Your name" formControlName="name"
                [class.is-invalid]="registerForm.get('name').hasError('required') && registerForm.get('name').touched">
                <div class="invalid-feedback" *ngIf="registerForm.get('name').hasError('required')&&registerForm.get('name').touched">Name is required</div>
            </div> 
            <div class="form-group"> 
               <div class="em-ph-item">
                    <div class="em">
                        <input type="email" class="form-control email" placeholder="Email" formControlName="email"
                        [class.is-invalid]="(registerForm.get('email').hasError('required')&&registerForm.get('email').touched) || invalidEmail">
                        <div class="invalid-feedback" *ngIf="registerForm.get('email').hasError('required')&&registerForm.get('email').touched">Email is required</div>
                        <div class="invalid-feedback" *ngIf="invalidEmail">Email is Invalid</div>
                    </div>
                   
                    <div>
                        <input type="text" class="form-control" placeholder="Phone" formControlName="phone"
                        [class.is-invalid]="registerForm.get('phone').hasError('required')&&registerForm.get('phone').touched">
                        <div class="invalid-feedback" *ngIf="registerForm.get('phone').hasError('required')&&registerForm.get('phone').touched">Phone is required</div>
                    </div> 
               </div>
            </div> 

            <div class="form-group"> 
                <input type="text" class="form-control" placeholder="Showroom name" formControlName="storeName"
                [class.is-invalid]="registerForm.get('storeName').hasError('required')&&registerForm.get('storeName').touched">
                <div class="invalid-feedback" *ngIf="registerForm.get('storeName').hasError('required')&&registerForm.get('storeName').touched">Showroom name is required</div>
            </div>
            <div class="form-group"> 
                <select type="text" class="form-control" placeholder="Showroom address" formControlName="storeLocation"
                    [class.is-invalid]="registerForm.get('storeLocation').hasError('required')&&registerForm.get('storeLocation').touched"> 
                    <option *ngFor="let loc of locations" [value]="loc.Slug">{{loc.Name}}</option>
                </select>
                <div class="invalid-feedback" *ngIf="registerForm.get('storeLocation').hasError('required')&&registerForm.get('storeLocation').touched">Location is required</div>
            </div>
            <div class="form-group"> 
                <textarea type="text" class="form-control" placeholder="Showroom address" formControlName="storeAddress" cols="4"
                    [class.is-invalid]="registerForm.get('storeAddress').hasError('required')&&registerForm.get('storeAddress').touched"> 
                </textarea>
                <div class="invalid-feedback" *ngIf="registerForm.get('storeAddress').hasError('required')&&registerForm.get('storeAddress').touched">Address is required</div>
            </div>

            <div class="form-group"> 
                <select type="text" class="form-control" placeholder="Showroom address" formControlName="category"
                    [class.is-invalid]="registerForm.get('category').hasError('required')&&registerForm.get('category').touched"> 
                    <option *ngFor="let cat of categories" [value]="cat.val">{{cat.name}}</option>
                </select>
                <div class="invalid-feedback" *ngIf="registerForm.get('category').hasError('required')&&registerForm.get('category').touched">Category is required</div>
            </div>

            <div class="form-group">  
                <input formControlName="brand"
                    [typeahead]="filteredBrands"
                    [typeaheadOptionsLimit]="5"
                    [typeaheadMinLength]="0" 
                    (typeaheadOnSelect)="typeaheadOnSelect($event)"
                    placeholder="Search brand name to add"
                    class="form-control">
                    <div class="badge-hold">
                        <div class="badge" *ngFor="let b of selectedBrands">
                            <span >{{b}}</span>
                            <app-icon (click)="removeBrand(b)" name="cross-12" size="10"></app-icon>
                        </div> 
                    </div>
            </div>
            <div>

                <button type="submit"  [ngClass]="{'btn-loading': calling}" class="btn btn-primary mt-2 mt-md-3">Submit</button>
            </div>
        </form>
    </div>
</div>

<div class="form-cont" *ngIf="store">
    <div class="header">
        <img src="assets/images/pngs/shop-request-verify-64.png" />
        <div>
            <h5>Verify Your Showroom</h5>
            <p>Verifying your showroom with <span style="color: #47991f;">cheapdam.com</span> will give you a verfication badge that makes people trust your showroom more, and a dashboard to cutomize your shop page with cover photo, logo and more!</p>
        </div>
    </div>

    <div class="card-body"> 
        <form [formGroup]="verificationForm" (ngSubmit)="onSubmitVerify($event)">
            <div class="form-group"> 
                <input type="text" class="form-control" placeholder="Your name" formControlName="name"
                [class.is-invalid]="verificationForm.get('name').hasError('required') && verificationForm.get('name').touched">
                <div class="invalid-feedback" *ngIf="verificationForm.get('name').hasError('required')&&verificationForm.get('name').touched">Name is required</div>
            </div> 
            <div class="form-group"> 
               <div class="em-ph-item">
                    <div class="em">
                        <input type="email" class="form-control email" placeholder="Email" formControlName="email"
                        [class.is-invalid]="(verificationForm.get('email').hasError('required')&&verificationForm.get('email').touched) || invalidEmail">
                        <div class="invalid-feedback" *ngIf="verificationForm.get('email').hasError('required')&&verificationForm.get('email').touched">Email is required</div>
                        <div class="invalid-feedback" *ngIf="invalidEmail">Email is Invalid</div>
                    </div>
                   
                    <div>
                        <input type="text" class="form-control" placeholder="Phone" formControlName="phone"
                        [class.is-invalid]="verificationForm.get('phone').hasError('required')&&verificationForm.get('phone').touched">
                        <div class="invalid-feedback" *ngIf="verificationForm.get('phone').hasError('required')&&verificationForm.get('phone').touched">Phone is required</div>
                    </div> 
               </div>
            </div> 

            <div class="form-group"> 
                <input type="text" class="form-control" placeholder="Showroom name" formControlName="storeName" readonly>
            </div>
            <div class="form-group"> 
                <select type="text" class="form-control" placeholder="Showroom address" formControlName="storeLocation" disabled> 
                    <option *ngFor="let loc of locations" [value]="loc.Slug">{{loc.Name}}</option>
                </select> 
            </div>
            <div class="form-group"> 
                <textarea type="text" class="form-control" placeholder="Showroom address" formControlName="storeAddress" cols="4" readonly> 
                </textarea> 
            </div> 
            <div>

                <button type="submit" [ngClass]="{'btn-loading': calling}" class="btn btn-primary mt-2 mt-md-3">Submit</button>
            </div>
        </form>
    </div>
</div>


