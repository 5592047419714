import { Inject, Injectable, PLATFORM_ID } from '@angular/core'; 
import { CartItem } from '../interfaces/cart-item';
import { BehaviorSubject, Observable, Subject, timer } from 'rxjs';
import { map } from 'rxjs/operators';
import { isPlatformBrowser } from '@angular/common';
import { CountryInfo, IDBProduct, OnlineProduct, ProductOption } from '../interfaces/product';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { UserService } from './user.service';
import { StorageService } from './storage.service';

interface CartTotal {
    title: string;
    price: number;
    type: 'shipping'|'fee'|'tax'|'other';
}

interface CartData {
    items: CartItem[];
    quantity: number;
    subtotal: number;
    totals: CartTotal[];
    total: number;
}

@Injectable({
    providedIn: 'root'
})
export class CartService {
    private data: CartData = {
        items: [],
        quantity: 0,
        subtotal: 0,
        totals: [],
        total: 0
    };

    private defaultCountry:CountryInfo = this.getCountryInfoFromStorage();

    public countryInfo:CountryInfo;

    private onCountryInfoChanged$: BehaviorSubject<CountryInfo> = new BehaviorSubject(this.defaultCountry);  
    private itemsSubject$: BehaviorSubject<CartItem[]> = new BehaviorSubject(this.data.items);
    private quantitySubject$: BehaviorSubject<number> = new BehaviorSubject(this.data.quantity);
    private subtotalSubject$: BehaviorSubject<number> = new BehaviorSubject(this.data.subtotal);
    private totalsSubject$: BehaviorSubject<CartTotal[]> = new BehaviorSubject(this.data.totals);
    private totalSubject$: BehaviorSubject<number> = new BehaviorSubject(this.data.total);
    private onAddingSubject$: Subject<IDBProduct> = new Subject();
    private onQuantityReached$: Subject<any> = new Subject();

    get items(): ReadonlyArray<CartItem> {
        return this.data.items;
    }

    get quantity(): number {
        return this.data.quantity;
    }

    readonly items$: Observable<CartItem[]> = this.itemsSubject$.asObservable();
    readonly quantity$: Observable<number> = this.quantitySubject$.asObservable();
    readonly subtotal$: Observable<number> = this.subtotalSubject$.asObservable();
    readonly totals$: Observable<CartTotal[]> = this.totalsSubject$.asObservable();
    readonly total$: Observable<number> = this.totalSubject$.asObservable(); 
    readonly onLimit$: Observable<any> = this.onQuantityReached$.asObservable();  
    readonly onAdding$: Observable<IDBProduct> = this.onAddingSubject$.asObservable();
    readonly onCountry:Observable<CountryInfo> = this.onCountryInfoChanged$.asObservable();
    baseUrl:string = environment.apiUrl;
    
    constructor(
        @Inject(PLATFORM_ID)
        private platformId: any,
        private http: HttpClient,
        private storage:StorageService,
        private userService:UserService) 
    {  
    }

    setGetMe(info:CountryInfo)
    {
        if(info && info.CurrencyMultiplier > 0)
        {
            this.setCountryInfoToStorage(info);
            this.onCountryInfoChanged$.next(info);
        }
        this.load();
        this.calc();
    }
 
    add(product: IDBProduct, quantity: number, option:ProductOption): Observable<any> 
    {      
        if(this.userService.isLoggedIn() == true)
        {
            return this.addOrUpdateCartItem(product.ID, quantity, option).pipe(map((res:any) => 
            {    
                this.onQuantityReached$.next(res);

                if(res && res.ID > 0)
                {
                    this.onAddingSubject$.next(product); 
                    let item = this.items.find(item => item.product.ID === product.ID && item.option.ID === option.ID)

                    if (item) 
                    {
                        item.quantity += quantity;
                    } 
                    else 
                    {
                        item = {product, quantity, option};
                        this.data.items.push(item);
                    }
        
                    this.calc(); 
                    this.save();
                    return item;
                } 
            }));
        }
        else
        {
            return timer(500).pipe(map(() => {
                this.onAddingSubject$.next(product);
    
                let item = this.items.find(item => item.product.ID === product.ID && item.option.ID === option.ID)

                if (item) 
                {
                    item.quantity += quantity;
                } 
                else 
                {
                    item = {product, quantity, option};
                    this.data.items.push(item);
                }
    
                this.calc(); 
                this.save();
                return item;
            })); 
        } 
    }

    update(updates: {item: CartItem, quantity: number}[]): Observable<void> 
    {  
        try{
            return this.addOrUpdateCartItem(updates[0].item.product.ID, updates[0].quantity, updates[0].item.option).pipe(map(() => {
                updates.forEach(update => {
                    const item = this.items.find(eachItem => eachItem === update.item);
    
                    if (item) {
                        item.quantity = update.quantity;
                    }
                });
    
                this.calc();
                this.save();
            }));
        }
        catch{}
        
    }

    remove(item: CartItem): Observable<void> 
    {     
        if(this.userService.isLoggedIn() == true)
        {
            return this.removeFromCart(item.product.ID, item.option.ID).pipe(map((res) => 
            { 
                if(res == true)
                {
                    this.data.items = this.data.items.filter(eachItem => eachItem !== item);
                    this.calc();
                    this.save();
                } 
            })); 
        }
        else
        {
            return timer(500).pipe(map(() => 
            {
                this.data.items = this.data.items.filter(eachItem => eachItem !== item);
                    this.calc();
                    this.save();
            }));
        } 
    }

    private calc(): void 
    {
        let quantity = 0;
        let subtotal = 0;

        this.data.items.forEach(item => 
        {
            quantity += item.quantity;
            subtotal += item.option.Price * item.quantity;
        });

        const totals: CartTotal[] = [];

        totals.push({
            title: 'Shipping',
            price: 0,
            type: 'shipping'
        });
        // totals.push({
        //     title: 'Tax',
        //     price: subtotal * 0.20,
        //     type: 'tax'
        // });

        const total = subtotal + totals.reduce((acc, eachTotal) => acc + eachTotal.price, 0);

        this.data.quantity = quantity;
        this.data.subtotal = subtotal;
        this.data.totals = totals;
        this.data.total = total;

        this.itemsSubject$.next(this.data.items);
        this.quantitySubject$.next(this.data.quantity);
        this.subtotalSubject$.next(this.data.subtotal);
        this.totalsSubject$.next(this.data.totals);
        this.totalSubject$.next(this.data.total);
    }
 
    public load(): void 
    {  
        const items = this.storage.getItem('cartItems'); 
        var idstr = ""; 
        var model:any[] = [];

        try
        {
            if (items) 
            {
                var jitems = JSON.parse(items) as CartItem[]; 
                for(var i = 0; i < jitems.length; i++)
                { 
                    model.push({ID:jitems[i].product.ID, OptionId:jitems[i].option.ID, Quantity:jitems[i].quantity})
                }
            }   
    
            this.getCartItemsFromDb(model).subscribe(res =>
            {
                if(res && res.length > 0)
                {
                    this.data.items = res; 
                    this.calc();
                }
            });
        }
        catch { } 

        if (items) 
        {
            this.data.items = JSON.parse(items);
            
        }
        else{
            this.data.items = []; 
            this.calc(); 
            this.save();
        }
    }
    public save(): void 
    {
        this.storage.setItem('cartItems', JSON.stringify(this.items));
    }

    getCountryInfoFromStorage(): CountryInfo | null 
    {
        try{
            const countryJSON = this.storage.getItem('defaultCn');
            if (countryJSON) {
                return JSON.parse(countryJSON) as CountryInfo;
            }
        }
        catch{}  
        var def:CountryInfo ={
            Name:"United States of America",
            CountryCode:"USA",
            CurrencyCode:"USD",
            CurrencySymbol:"$",
            CurrencyMultiplier:1
        }
        return def;
    }

    setCountryInfoToStorage(country: CountryInfo): void 
    {
        try{
            const countryJSON = JSON.stringify(country);
            this.storage.setItem('defaultCn', countryJSON);
        }
        catch{}
    }

    getCartItemsFromDb(model:any[]): Observable<CartItem[]> 
    {   
        return this.http.post<CartItem[]>(this.baseUrl +`/api/UserProductItem/GetAllCartItems`, model);
    }

    addOrUpdateCartItem(prodId:number, quantity:number, option:ProductOption): Observable<CartItem[]> 
    { 
        const params:{[param:string]:string} = {};  

        return this.http.get<CartItem[]>(this.baseUrl +`/api/UserProductItem/AddToCart/${prodId}/${option.ID}/${quantity}`, {params}); 
    }

    removeFromCart(prodId:number, optionId:number): Observable<boolean> 
    { 
        const params:{[param:string]:string} = {};  
        return this.http.get<boolean>(this.baseUrl +`/api/UserProductItem/RemoveFromCart/${prodId}/${optionId}`, {params}); 
    }

    placeOrder(model:any): Observable<any> 
    {  
        return this.http.post<any>(this.baseUrl +`/api/Order/CreateOrder/`, model); 
    }

    getAllUserOrder(model:any): Observable<any> 
    {  
        return this.http.post<any>(this.baseUrl +`/api/Order/GetAllUserOrder/`, {}); 
    }

    getAllNijerOrder(model:any): Observable<any> 
    {  
        return this.http.post<any>(this.baseUrl +`/api/Publisher/GetAllNijerOrder/`, {}); 
    }

    processSingleOrderRequest(model:any): Observable<any> 
    {    
        return this.http.post<any>(this.baseUrl +`/api/Publisher/ProcessSingleOrderRequest/`, model);   
    }

    processOder(model:any): Observable<any> 
    {  
        return this.http.post<any>(this.baseUrl +`/api/Publisher/ProcessOrderRequest/`, model); 
    }

    getAllShopOrder(model:any): Observable<any> 
    {  
        return this.http.post<any>(this.baseUrl +`/api/Order/GetAllShopOrder/`, {}); 
    }

    getOrder(orderId:any, action = 're'): Observable<any> 
    {  
        return this.http.get<any>(this.baseUrl +`/api/Order/GetOrder?action=${action}&id=${orderId}`); 
    }

    processShopOrder(model:any): Observable<any> 
    {  
        return this.http.post<any>(this.baseUrl +`/api/Order/ProcessShopOrder/`, model); 
    }

    getMe(): Observable<any> 
	{
		return this.http.get<any>(this.baseUrl + `/api/Locale/me`, {});
	}

    getMe2(): Observable<any> 
	{
		return this.http.get<any>(this.baseUrl + `/api/Locale/me2`, {});
	}
}
