<ul class="social-links__list">
    <li *ngFor="let item of items" class="social-links__item">
        <a
            class="social-links__link social-links__link--type--{{ item.type }}"
            target="_blank"
            rel="noopener noreferrer"
            [title]="item.type"
            [href]="item.url"
        >
            <app-icon size="24" [name]="item.icon" style="fill: #fff;"></app-icon>
        </a>
    </li>
</ul>
