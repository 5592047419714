<div class="alert-box">
    <div class="modal-header">
        <h4 class="modal-title">Confirm Action</h4> 
    </div> 

    <div style="padding: 40px;">
        <h5 *ngIf="msg">{{msg}}</h5>
        <h5 *ngIf="!msg">Are you sure you?</h5> 
    </div>

    <div class="modal-footer" style="justify-content: space-between !important;">
        <button type="button" class="btn btn-secondary" (click)="onConfirm()">Yes</button>
        <button type="button" class="btn btn-success" (click)="onCancel()">No</button>        
    </div>
</div>