import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, of } from 'rxjs';
import { Category } from '../interfaces/category';
import { HttpClient } from '@angular/common/http';
import { Brand } from '../interfaces/brand';
import { IDBProduct, OnlineProduct } from '../interfaces/product';
import { DbProductsList } from '../interfaces/list';
import { Filter, SerializedFilterValues } from '../interfaces/filter';
import { environment } from 'src/environments/environment'; 
import { ProductsService } from '../services/products.service';
import { Review } from '../interfaces/review';
import { StorageService } from '../services/storage.service';
// import {
//     getBestsellers,
//     getFeatured,
//     getLatestProducts,
//     getProduct,
//     getRelatedProducts,
//     getSpecialOffers,
//     getTopRated,
//     getShopCategoriesBySlugs,
//     getShopCategoriesTree,
//     getShopCategory,
//     getBrands,
//    // getProductsList,
// } from '../../../fake-server';
//import { getSuggestions } from 'src/fake-server/database/products';

export interface ListOptions {
    page?: number;
    limit?: number;
    sort?: string;
    badges?:string;
    text?:string;
    filterValues?: SerializedFilterValues;
}
 
@Injectable({
    providedIn: 'root'
})
export class ShopService 
{
    baseUrl:string = environment.apiUrl; 
    constructor(
        private http: HttpClient, private storage:StorageService
    ) 
    { }

    /**
     * Returns category object by slug.
     *
     * @param slug - Unique human-readable category identifier.
     */
    getCategory(slug: string): Observable<Category> 
    {
        /**
         * This is what your API endpoint might look like:
         *
         * https://example.com/api/shop/categories/power-tools.json
         *
         * where:
         * - power-tools = slug
         */
        // return this.http.get<Category>(`https://example.com/api/shop/categories/${slug}.json`);

        // This is for demonstration purposes only. Remove it and use the code above.
        return; //getShopCategory(slug);
    }

    /**
     * Returns a category tree.
     *
     * @param parent - If a parent is specified then its descendants will be returned.
     * @param depth  - Maximum depth of category tree.
     */
    getCategories(parent: Partial<Category> = null, depth: number = 0): Observable<Category[]> 
    {   
        return this.http.get<Category[]>(this.baseUrl +'/api/Category/GetPopularCategories'); 
    }

    getPopularCategories(): Observable<Category[]> 
    {  
        return this.http.get<Category[]>(this.baseUrl +'/api/Category/GetPopularCategories'); 
    }

    /**
     * Returns an array of the specified categories.
     *
     * @param slugs - Array of slugs.
     * @param depth - Maximum depth of category tree.
     */
    getCategoriesBySlug(slugs: string[], depth: number = 0): Observable<Category[]>
    {
        /**
         * This is what your API endpoint might look like:
         *
         * https://example.com/api/shop/categories.json?slugs=power-tools,measurement&depth=1
         *
         * where:
         * - slugs = slugs.join(',')
         * - depth = depth
         */
        // const params: {[param: string]: string} = {
        //     slugs: slugs.join(','),
        //     depth: depth.toString(),
        // };
        //
        // return this.http.get<Category[]>('https://example.com/api/shop/categories.json', {params});

        // This is for demonstration purposes only. Remove it and use the code above.
        return ;//getShopCategoriesBySlugs(slugs, depth);
    }

    /**
     * Returns paginated products list.
     * If categorySlug is null then a list of all products should be returned.
     *
     * @param categorySlug         - Unique human-readable category identifier.
     * @param options              - Options.
     * @param options.page         - Page number (optional).
     * @param options.limit        - Maximum number of items returned at one time (optional).
     * @param options.sort         - The algorithm by which the list should be sorted (optional).
     * @param options.filterValues - An object whose keys are filter slugs and values ​​are filter values (optional).
     */
    getProductsList(categorySlug: string|null, brandSlug: string|null, q:string|null, options: ListOptions, type: string|null = '', shopUid: string|null = ''): Observable<DbProductsList> 
    {  
        return this.getProdCall(categorySlug, brandSlug, q, options, type, shopUid)  
    }

    getProdCall(categorySlug: string|null, brandSlug: string|null, query:string|null, options: ListOptions, type: string|null = '', shopUid: string|null = ''): Observable<DbProductsList>
    {
        const filtersDef = [
            {type: 'range', slug: 'price', name: 'Price'},
            // {type: 'link', slug: 'brand', name: 'Brand'},
           // {type: 'radio', slug: 'discount', name: 'With Discount'},
          //  {type: 'color', slug: 'color', name: 'Color'},
        ]; 
 
        const page = options.page || 1;
        const limit = options.limit || 12;
        const sort = options.sort || 'default';
        const badge = type || ''; 
        const text = query || '';
        const filterValues = options.filterValues || {}; 
        const filters: Filter[] = this.makeFilters(filtersDef);   
        var model:any = {};
        model.page = page;
        model.limit = limit;
        model.sort = sort;
        model.badges = badge;
        model.filters = filters;
        model.text = text;
        model.filterValues = JSON.stringify(filterValues);
        model.catSlug = categorySlug;
        model.brandSlug = brandSlug;  

        if (shopUid && shopUid.trim() !== '') 
        {
            return this.http.get<DbProductsList>(this.baseUrl+'/api/UserProduct/GetUserProducts?suid='+shopUid); 
        }
        else
        {
            return this.http.post<DbProductsList>(this.baseUrl+'/api/Product/GetProducts', model); 
        } 
    }

    getShopAvailableSKU(): Observable<string[]> 
    {  
        return this.http.get<string[]>(this.baseUrl +'/api/UserProduct/GetAllAvailableSKUs'); 
    }

    addNewProduct(product:IDBProduct):Observable<IDBProduct>
    {
       return this.http.post<any>(this.baseUrl+'/api/UserProduct/AddNewProduct', product);
    }

    uploadProductImage(files:FormData):Observable<string[]>
    {
       return this.http.post<any>(this.baseUrl+'/api/Image/UploadProductImage', files);
    }

    getProduct(productSlug: string): Observable<IDBProduct> 
    { 
        const params: {[param: string]: string} = {}; 
        if (productSlug) 
        {
            params.slug = productSlug;
        }

        return this.http.get<IDBProduct>(this.baseUrl+`/api/Product/GetProductBySlug`, {params}); 
    }

    /**
     * Returns popular brands.
     */
    getPopularBrands(): Observable<Brand[]> 
    { 
        return this.http.get<Brand[]>(this.baseUrl+'/api/Category/GetAllBrands');  
    }

    getBrandsForPage(type:string, limit:number): Observable<any> 
	{ 
        return this.http.get<any>(environment.apiUrl + `/api/Category/GetBrandsForPage?type=${type}&limit=${limit}`); 
    } 
    
    getTopRated(categorySlug: string, limit: number = 12, brandSlug: string = ''): Observable<OnlineProduct[]> 
    {
        return this.http.get<OnlineProduct[]>(this.baseUrl+`/api/Product/GetTopRatedProducts?cat=${categorySlug}&brand=${brandSlug}&limit=${limit}`); 
    } 

    getFeaturedProducts(categorySlug: string, limit: number = 12, brandSlug: string = ''): Observable<OnlineProduct[]> 
    { 
        return this.http.get<OnlineProduct[]>(this.baseUrl+`/api/Product/GetFeaturedProducts?cat=${categorySlug}&brand=${brandSlug}&limit=${limit}`); 
    }

    getLatestProducts(categorySlug: string = '', limit: number = null, brandSlug: string = ''): Observable<OnlineProduct[]> 
    { 
        return this.http.get<OnlineProduct[]>(this.baseUrl+`/api/Product/GetLatestProducts?cat=${categorySlug}&brand=${brandSlug}&limit=${limit}`); 
    }

    getRelatedProducts(product: Partial<OnlineProduct>): Observable<OnlineProduct[]> 
    {
        const params: {[param: string]: any} = {}; 
        params.categorySlug = product.CategorySlug;
        params.productName = product.Name;
        params.puid = product.PUID;
        params.categoryTree = product.CategoryTree; 
        params.brandSlug = product.BrandSlug;  
        params.price = product.Price;

        return this.http.get<OnlineProduct[]>(this.baseUrl+'/api/Product/GetRealatedProducts', {params}); 
    }

    getSuggestions(query: string, categorySlug: string = null): Observable<OnlineProduct[]> {
       
        const params: {[param: string]: string} = {query}; 
        if (categorySlug) {
            params.category = categorySlug;
        } 

        return this.http.get<OnlineProduct[]>(this.baseUrl+'/api/Product/GetSearchSuggestions', {params}); 
    }

    //#region Review
    postReview(model:any): Observable<boolean> { 
        return this.http.post<boolean>(this.baseUrl+'/api/Review/PostNewReview', model); 
    }
    getReviews(puid:any): Observable<Review[]> { 
        return this.http.get<Review[]>(this.baseUrl+'/api/Review/GetProductReviews/'+puid); 
    }
    //#endregion

    makeFilters(filtersDef, min = 0, max = 500000) 
    {
        const result = []; 
        filtersDef.forEach(filterDef => 
        {
            if (filterDef.type === 'range') 
            { 
                /** Calculates the number of digits for rounding. */
                let digit = Math.max(Math.ceil(max).toString().length - 2, 1);
    
                digit = Math.pow(10, digit);
                max = Math.ceil(max / digit) * digit;
                min = Math.floor(min / digit) * digit;
    
                result.push({
                    type: filterDef.type,
                    slug: filterDef.slug,
                    name: filterDef.name,
                    value: [min, max],
                    // options
                    min,
                    max,
                });
            } 
            else if (filterDef.type === 'check' || filterDef.type === 'radio' || filterDef.type === 'color' || filterDef.type === 'link') 
            {  
                result.push({
                    type: filterDef.type,
                    slug: filterDef.slug,
                    name: filterDef.name,
                    value: filterDef.type === 'radio' ? [] : [],
                    items:[],
                });
            }
        });
    
        return result;
    } 


    setRecentView(product: any): void 
    { 
        let views: OnlineProduct[] = JSON.parse(this.storage.getItem('views') || '[]');
       
        const existingIndex = views.findIndex((item) => item.ID === product.ID);
        if (existingIndex !== -1) 
        { 
          views.splice(existingIndex, 1);
        }
       
        views.push(product);
       
        if (views.length > 15) {
          views.shift();  
        }
       
        this.storage.setItem('views', JSON.stringify(views));
    }

    getRecentViews():Observable<OnlineProduct[]> { 
        const views = this.storage.getItem('views'); 
        const parsedViews: OnlineProduct[] = views ? JSON.parse(views) : [];
        return of(parsedViews);
    }
}
