//import { isPlatformBrowser } from '@angular/common';
import { Component, EventEmitter, Inject, Output, PLATFORM_ID } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { AccountService } from 'src/app/modules/account/account.service';
import { CartService } from 'src/app/shared/services/cart.service';
import { CompareService } from 'src/app/shared/services/compare.service';
import { UserService } from 'src/app/shared/services/user.service';
import { WishlistService } from 'src/app/shared/services/wishlist.service';

@Component({
    selector: 'app-account-menu',
    templateUrl: './account-menu.component.html',
    styleUrls: ['./account-menu.component.scss']
})
export class AccountMenuComponent 
{
    @Output() closeMenu: EventEmitter<void> = new EventEmitter<void>();
    
    loginFormMenu:FormGroup = this.formBuilder.group({
        email: '',
        password: ''
    });
    userInfo:any = {};
    isLoggedIn:boolean;
    defaultRoute:string = 'myaccount'
    constructor(private formBuilder: FormBuilder,
       // @Inject(PLATFORM_ID) private platformId: any,
        private toastr:ToastrService,
        private accountService:AccountService,
        private router:Router,
        private cartSer:CartService, 
		private wishSer:WishlistService,
		private compSer:CompareService,
        private userService:UserService) 
    { 
        this.userService.onUserInfoChanged$.subscribe(res =>
        { 
            this.isLoggedIn = this.userService.isLoggedIn();
            this.userInfo = res;
            if(this.userInfo && this.userService.userType() != this.userService.userTypeConst.shopper){
                this.defaultRoute = 'dashboard'
            } 
        });
    }

    onSubmit(event:any): void 
    {  
        event.preventDefault();  
        var model:any = {};
        model.Email = this.loginFormMenu.value.email;
        model.Password = this.loginFormMenu.value.password;
        var em = this.loginFormMenu.value.email;

        this.accountService.login(model).subscribe(res => 
        { 
            if(res === 1)
            {
                this.toastr.error(`Invalid login information!`);
            }
            else if(res === 2)
            {
                this.toastr.error(`Your account has been deactivated, please contact support!`);
            }
            else if(res === 3)
            {
                this.toastr.error(`Your email is not confirmed, use forget password to get new email confirmation link!`);
            }
            else if(res === null || res === 0)
            {
                this.toastr.error(`Something went wrong, try again!`);
            }
            else
            {
                this.userService.setToken(res);  
                
                this.userService.getAndInitUserInfo(em).subscribe(
                {
                    complete:()=>
                    { 
                        this.closeMenu.emit();
                        this.cartSer.load();
                        this.wishSer.load();
                        this.compSer.load();

                        // if (isPlatformBrowser(this.platformId)){
                        //     window.location.reload();
                        // } 
                    }
                });
            } 
        }, error=>
        {
            this.toastr.error(`Something went wrong, try again!`);
        }); 
    }

    logout()
    {
        this.userService.onLogout();
        this.closeMenu.emit();
        this.cartSer.load();
        this.wishSer.load();
        this.compSer.load();   
        // if (isPlatformBrowser(this.platformId)){ 
        //     this.router.navigate(['/']).then(() => { 
        //         window.history.pushState(null, '', window.location.href);
        //         window.history.back();
        //         window.history.forward();
        //     }); 
        //     window.location.reload();
        // }
    }
}
