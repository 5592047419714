import { MobileMenuItem } from '../app/shared/interfaces/mobile-menu-item';

export const mobileMenu: MobileMenuItem[] = [
  {
    "type": "link",
    "label": "Home Decor",
    "url": "/products/home-decor",
    "children": [
      {
        "type": "link",
        "label": "Exclusive Collection",
        "url": "/products/home-decor"
      },
      {
        "type": "link",
        "label": "All Collection",
        "url": "/products/home-decor"
      }
    ]
  },
  {
    "type": "link",
    "label": "Wall Decor",
    "url": "/products/wall-decor",
    "children": [
      {
        "type": "link",
        "label": "Wall Clock",
        "url": "/products/wall-clock"
      },
      {
        "type": "link",
        "label": "Wall Stickers",
        "url": "/products/wall-sticker"
      },
      {
        "type": "link",
        "label": "Wall Hanging Items",
        "url": "/products/wall-hanging"
      }
    ]
  },
  {
    "type": "link",
    "label": "Car Accessories",
    "url": "/products/car-decor",
    "children": [
      {
        "type": "link",
        "label": "Car Air Freshener",
        "url": "/products/car-air-freshener"
      },
      {
        "type": "link",
        "label": "Car Decor",
        "url": "/products/car-accessories"
      }
    ]
  },
  {
    "type": "link",
    "label": "Toys",
    "url": "/products/toys",
    "children": [
      {
        "type": "link",
        "label": "Learning Toys",
        "url": "/products/learning-toys"
      },
      {
        "type": "link",
        "label": "All Toys",
        "url": "/products/toys"
      },
      {
        "type": "link",
        "label": "Car Models",
        "url": "/products/toy-car-models"
      }
    ]
  },
  {
    "type": "link",
    "label": "Jewelery And Watches",
    "url": "/products/jewelery-and-watches",
    "children": [
      {
        "type": "link",
        "label": "Watches",
        "url": "/products/watches"
      },
      {
        "type": "link",
        "label": "Jewelery And Style",
        "url": "/products/jewelery"
      }
    ]
  },
  {
    "type": "link",
    "label": "Gadget And Gears",
    "url": "/products/gadget-and-gears",
    "children": [
      {
        "type": "link",
        "label": "Phone Gadgets",
        "url": "/products/phone-gadgets"
      },
      {
        "type": "link",
        "label": "Laptop And PC Gadgets",
        "url": "/products/laptop-and-pc-gadgets"
      }
    ]
  },
  {
    "type": "link",
    "label": "Health And Life",
    "url": "/products/health-and-life",
    "children": [
      {
        "type": "link",
        "label": "Hair Style",
        "url": "/products/hair-style"
      },
      {
        "type": "link",
        "label": "Skin Care",
        "url": "/products/skin-care"
      }
    ]
  },
  {
    "type": "link",
    "label": "Limited Edition",
    "url": "/products/accessories"
  },
  {
    "type": "link",
    "label": "Fashion & Clothing",
    "url": "/products/clothing",
    "children": [
      {
        "type": "link",
        "label": "Men's Clothing",
        "url": "/products/men-clothing"
      },
      {
        "type": "link",
        "label": "Women's Clothing",
        "url": "/products/women-clothing"
      }
    ]
  }
];
