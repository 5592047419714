import { Inject, Injectable } from '@angular/core';
import { IDBProduct, OnlineProduct } from '../interfaces/product';
import { Category } from '../interfaces/category';
import { Meta, Title } from '@angular/platform-browser';
import { MetaTag } from '../interfaces/metatag';
import { DOCUMENT } from '@angular/common';

@Injectable({
    providedIn: 'root'
})
export class RootService {

    constructor(private title: Title,
        private meta: Meta, @Inject(DOCUMENT) private document: Document,) { 
    }

    home(): string {
        return '/';
    }

    shop(): string {
        return `/products`;
    }

    category(category: Partial<Category>): string {
        if (category.type === 'products') {
            const basePath = this.shop();

            if ('slug' in category) {
                return `${basePath}/${category.slug}`;
            }
            if ('id' in category) {
                return `${basePath}/${category.id}`;
            }

            throw Error('Provide category with "path", "slug" or "id".');
        }
        if (category.type === 'blog') {
            return this.blog(category.slug);
        }

        throw Error('Provided category with unknown type.');
    }

    product(product: Partial<OnlineProduct>): string {
        const basePath = '/products/details';

        // if ('Slug' in product) {
        //     return `${basePath}/${product.Slug}`;
        // }
        if ('Slug' in product) {
            return `${basePath}/${product.Slug}`;
        }

        throw Error('Provide product with "slug" or "id".');
    }

    productBySlug(slug): string {
        const basePath = '/products/details';

        return `${basePath}/${slug}`;
        throw Error('Provide product with "slug" or "id".');
    }

    categoryBySlug(categorySlug: string): string {
        const basePath = this.shop();

        return `${basePath}/${categorySlug}`;
    }

    brand(brand: string): string {
        return '/products/brand/' + brand;
    }

    brandWithCat(brand: string, cat: string): string {
        return '/products/brand/' + brand + "/" + cat;
    }

    cart(): string {
        return '/account/cart';
    }

    checkout(): string {
        return '/account/checkout';
    }

    wishlist(): string {
        return '/account/wishlist';
    }

    compare(): string {
        return '/account/compare';
    }

    blog(cat:string = ''): string {
        return '/blog'
    }

    post(slug:string): string {
        return `/blog/${slug}`;
    }

    login(): string {
        return '/account/login';
    }

    terms(): string {
        return '/site/terms';
    }

    notFound(): string {
        return `/site/not-found`;
    }

    setmeta(title: string, meta: MetaTag = null) {
        if (title) {
            this.title.setTitle(title + " | IzzLifeStyle");
        }
        else {
            this.title.setTitle("IzzLifestyle - Designed to bring you a seamless shopping experience with premium products and free shipping");
        }

        if (meta && meta != null) 
        {
            var des = meta.og_description;
            if (des && des != null) {
                if (des.length > 155) {
                    des = des.substring(0, 155)
                }
            }
            else{
                meta.og_description = des = "Izzlifestyle is your trusted global e-commerce platform, designed to bring you a seamless shopping experience. We specialize in offering a wide range of high-quality products, ensuring fast delivery and affordable pricing. Whether you’re looking for trendy fashion, gadgets, or lifestyle products, we connect you to top manufacturers from around the world.";
            }
            this.meta.updateTag(
                { 
                    name: 'description',
                    content: des
                });

            this.meta.updateTag(
                {
                    property: 'og:title',
                    content: meta.og_title,
                });

            this.meta.updateTag(
                {
                    property: 'og:description',
                    content: meta.og_description,
                });

            this.meta.updateTag(
                {
                    property: 'og:image:secure_url',
                    content: meta.og_image ? meta.og_image : 'https://www.izzlifestyle.com/assets/images/logos/sea-cotton.png',
                });

            this.meta.updateTag(
                {
                    property: 'og:image',
                    content: meta.og_image ? meta.og_image : 'https://www.izzlifestyle.com/assets/images/logos/sea-cotton.png',
                });

            this.meta.updateTag(
                {
                    property: 'og:image:alt',
                    content: title,
                });

            this.meta.updateTag(
                {
                    property: 'og:type',
                    content: meta.og_type,
                });

            this.meta.updateTag(
                {
                    property: 'og:sitename',
                    content: "izzlifestyle"
                });

            this.meta.updateTag(
                {
                    property: 'og:url',
                    content: meta.og_url ? meta.og_url : this.document.URL
                });

        }
        else {
            this.meta.updateTag(
                {
                    property: 'description',
                    content: 'Izzlifestyle is your trusted global e-commerce platform, designed to bring you a seamless shopping experience. We specialize in offering a wide range of high-quality products, ensuring fast delivery and affordable pricing. Whether you’re looking for trendy fashion, gadgets, or lifestyle products, we connect you to top manufacturers from around the world.'
                });

            this.meta.updateTag(
                {
                    property: 'og:description',
                    content: 'Izzlifestyle is your trusted global e-commerce platform, designed to bring you a seamless shopping experience. We specialize in offering a wide range of high-quality products, ensuring fast delivery and affordable pricing. Whether you’re looking for trendy fashion, gadgets, or lifestyle products, we connect you to top manufacturers from around the world.'
                });

            if (title) {
                this.meta.updateTag(
                    {
                        property: 'og:title',
                        content: title,
                    });
            }
            else {
                this.meta.updateTag(
                    {
                        property: 'og:title',
                        content: "Izzlifestyle is your trusted global e-commerce platform, ensuring fast delivery and affordable pricing. Whether you’re looking for trendy fashion, gadgets, or lifestyle products.",
                    });
            }

            this.meta.updateTag(
                {
                    property: 'og:image:secure_url',
                    content: 'https://www.izzlifestyle.com/assets/images/logos/sea-cotton.png',
                });

            this.meta.updateTag(
                {
                    property: 'og:image',
                    content: 'https://www.izzlifestyle.com/assets/images/logos/sea-cotton.png',
                });
        }
    }

    removeStructuredData(className:string): void {
        try {
            const els = [];
            [className, 'structured-data-org'].forEach(c => {
                els.push(...Array.from(this.document.head.getElementsByClassName(c)));
            });
            els.forEach(el => this.document.head.removeChild(el));
        }
        catch { }
    }

    stripHtmlTags(input: string): string {
        return input.replace(/<\/?[^>]+(>|$)/g, '');
    }

    insertReviewSchema(product: IDBProduct, className = 'structured-data-product'): void 
    { 
        try
        {
            this.removeStructuredData(className)
            const rndInt = Math.floor(Math.random() * 10) + 1;
            const des = product.ShortDescription.length < 500 ? this.stripHtmlTags( product.ShortDescription) :this.stripHtmlTags( product.ShortDescription).substring(0, 500);
            var schema = {
                "@context": "https://schema.org/",
                "@type": "Product",
                "name": product.Name,
                "description": des,
                "image": product.Images,
                "review": {
                    "@type": "Review",
                    "reviewRating": {
                        "@type": "Rating",
                        "ratingValue": product.Rating ? product.Rating : 4.5,
                        "bestRating": "5"
                    },
                    "author": {
                        "@type": "Organization",
                        "name": "izzlifestyle.com"
                    }
                },
                "aggregateRating": {
                    "@type": "AggregateRating",
                    "ratingValue": product.Rating ? product.Rating : 4.5,
                    "reviewCount": rndInt
                },
                "brand": {
                    "@type": "Brand",
                    "name": product.BrandName
                },
                "offers": {
                    "@type": "Offer",
                    "url":"https://www.izzlifestyle.com/products/details/"+product.Slug,
                    "priceCurrency": "USD",   
                    "price": product.Price,   
                    "availability": "https://schema.org/InStock",
                    "itemCondition": "https://schema.org/NewCondition",   
                    "priceValidUntil": "2024-12-31",
                    "priceSpecification": {
                        "@type": "UnitPriceSpecification",
                        "price": product.Price,
                        "priceCurrency": "USD"
                    },
                    "shippingDetails": {
                        "@type": "OfferShippingDetails",
                        "shippingDestination": {
                            "@type": "DefinedRegion",
                            "name": "USA, Canada",
                            "addressCountry":"USA, Canada"
                        },
                        "shippingRate": {
                            "@type": "MonetaryAmount",
                            "value": "0.00",
                            "currency": "USD"
                        },
                        "shippingMethod": "https://schema.org/StandardShipping",
                        "deliveryTime": {
                            "@type": "ShippingDeliveryTime", 
                            "maxValue": "5",
                            "minValue": "3",
                            "unitCode": "d", 
                            "value": "3-8 days",
                            "handlingTime":"2-3 days",
                            "transitTime": {
                                "@type": "QuantitativeValue",
                                "minValue": 2,
                                "maxValue": 5,
                                "unitCode": "d" 
                            }
                        }
                    },
                    "hasMerchantReturnPolicy": true,
                    "merchantReturnPolicy": {
                        "@type": "MerchantReturnPolicy",
                        "returnPolicyCategory": "https://schema.org/ReturnPolicyReturnOnly",
                        "returnFees": {
                            "@type": "MonetaryAmount",
                            "value": "0.00",
                            "currency": "USD"
                        },
                        "returnPolicySeasonalOverride": "https://schema.org/NotApplicable",
                        "returnMethod": "https://schema.org/ReturnByMail",
                        "returnPolicyCountry": "USA",
                        "eligibleRegion": {
                            "@type": "DefinedRegion",
                            "name": "USA"
                        },
                        "returnDuration": {
                            "@type": "QuantitativeValue",
                            "value": 7,
                            "unitCode": "d",
                            "name": "Days",
                            "minValue": "7"
                        }
                    }
                }
            }
            let script;
            let shouldAppend = false;
            if (this.document.head.getElementsByClassName(className).length) {
                script = this.document.head.getElementsByClassName(className)[0];
            } else {
                script = this.document.createElement('script');
                shouldAppend = true;
            }
            script.setAttribute('class', className);
            script.type = 'application/ld+json';
            script.text = JSON.stringify(schema);
            if (shouldAppend) {
                this.document.head.appendChild(script);
            }
        }
        catch{}
    }

    insertHomeSchema(className = 'schema-breadcrumb'): void {
        this.removeStructuredData(className)
        var schema = [{
            "@context": "https://schema.org",
            "@type": "BreadcrumbList",
            "itemListElement": [{
                "@type": "ListItem",
                "position": 1,
                "name": "Explore Lifestyle Essentials and More",
                "item": "https://www.izzlifestyle.com/products"
            }, {
                "@type": "ListItem",
                "position": 2,
                "name": "IzzLifeStyle Your Ultimate Online Store for Everyday Needs",
                "item": "https://www.izzlifestyle.com/products/home-decor"
            }, {
                "@type": "ListItem",
                "position": 3,
                "name": "Discover Trendy Gadgets, Car Decor, Toys, and More at Izzlifestyle",
                "item": "https://www.izzlifestyle.com/products/accessories"
            }]
        },
        {
            "@context": "https://schema.org",
            "@type": "BreadcrumbList",
            "itemListElement": [{
                "@type": "ListItem",
                "position": 1,
                "name": "Upgrade Your Car Decor with Trendy Accessories from Izzlifestyle",
                "item": "https://www.izzlifestyle.com/products/car-decor"
            },
            {
                "@type": "ListItem",
                "position": 2,
                "name": "Find Anything You Need for a Modern Lifestyle – Shop Now",
                "item": "https://www.izzlifestyle.com/products/wall-decor"
            },
            {
                "@type": "ListItem",
                "position": 3,
                "name": "Shop Toys, Tech, and Trendy Home Decor at Affordable Prices",
                "item": "https://www.izzlifestyle.com/products/"
            }]
        },
        {
            "@context": "https://schema.org",
            "@type": "BreadcrumbList",
            "itemListElement": [{
                "@type": "ListItem",
                "position": 1,
                "name": "Everything You Need for Your Lifestyle, in One Place",
                "item": "https://www.izzlifestyle.com/products/clothing"
            },
            {
                "@type": "ListItem",
                "position": 2,
                "name": "Buy Top Quality Womens Underwears",
                "item": "https://www.izzlifestyle.com/products/underwear"
            },
            {
                "@type": "ListItem",
                "position": 3,
                "name": "Buy Top Quality Jewelery And Watches At Discounted Price",
                "item": "https://www.izzlifestyle.com/products/jewelery-and-watches"
            }]
        },
        {
            "@context": "https://schema.org",
            "@type": "BreadcrumbList",
            "itemListElement": [{
                "@type": "ListItem",
                "position": 1,
                "name": "From Toys to Gadgets Everything at Izzlifestyle",
                "item": "https://www.izzlifestyle.com/products/toys"
            },
            {
                "@type": "ListItem",
                "position": 2,
                "name": "Fashion, Tech, and Decor Come Together At Izzlifestyle",
                "item": "https://www.izzlifestyle.com/products/home-decor"
            },
            {
                "@type": "ListItem",
                "position": 3,
                "name": "Your Ultimate Online Store for Everyday Needs",
                "item": "https://www.izzlifestyle.com/products/"
            }]
        }
        ]
        let script; 
        let shouldAppend = false;
        if (this.document.head.getElementsByClassName(className).length) {
            script = this.document.head.getElementsByClassName(className)[0];
        } else {
            script = this.document.createElement('script');
            shouldAppend = true;
        }
        script.setAttribute('class', className);
        script.type = 'application/ld+json';
        script.text = JSON.stringify(schema);
        if (shouldAppend) {
            this.document.head.appendChild(script);
        }
    }

    insertListPageSchema(brand:string|null, cat:string|null, className = 'schema-breadcrumb'): void {
        this.removeStructuredData(className)
        var schema = {
            "@context": "https://schema.org",
            "@type": "BreadcrumbList",
            "itemListElement": [{
                "@type": "ListItem",
                "position": 1,
                "name": "Products",
                "item": "https://www.izzlifestyle.com/products"
            }
        ]};
 
        if(brand && cat){
            schema['itemListElement'].push({
                "@type": "ListItem",
                "position": 2,
                "name": brand+" Products Buy Easy, Top Quality And Free Shipping",
                "item": "https://www.izzlifestyle.com/products/"+brand
            });

            schema['itemListElement'].push({
                "@type": "ListItem",
                "position": 3,
                "name": brand + " "+cat+ " Buy Online, At Best Price, Free Delivery",
                "item": "https://www.izzlifestyle.com/products/"+brand+"/"+cat
            })
        }
        else if(brand && !cat){
            schema['itemListElement'].push({
                "@type": "ListItem",
                "position": 2,
                "name": brand+" Buy Online, Easy, Top Quality And Free Shipping",
                "item": "https://www.izzlifestyle.com/products/"+brand
            }); 
        }
        else if(!brand && cat){ 
            schema['itemListElement'].push({
                "@type": "ListItem",
                "position": 2,
                "name": cat+ " Buy Online, Easy, Top Quality And Free Shipping",
                "item": "https://www.izzlifestyle.com/products/"+cat
            })
        }

        let script; 
        let shouldAppend = false;
        if (this.document.head.getElementsByClassName(className).length) {
            script = this.document.head.getElementsByClassName(className)[0];
        } else {
            script = this.document.createElement('script');
            shouldAppend = true;
        }
        script.setAttribute('class', className);
        script.type = 'application/ld+json';
        script.text = JSON.stringify(schema);
        if (shouldAppend) {
            this.document.head.appendChild(script);
        }
    }

    insertProductDetailsSchema(productName: string, categoryName: string, slug: string, className = 'schema-breadcrumb'): void {  
        this.removeStructuredData(className)
        const schema = {
            "@context": "https://schema.org",
            "@type": "BreadcrumbList",
            "itemListElement": [
                {
                    "@type": "ListItem",
                    "position": 1,
                    "name": "Home",
                    "item": "https://www.izzlifestyle.com"
                },
                {
                    "@type": "ListItem",
                    "position": 2,
                    "name": "Home",
                    "item": "https://www.izzlifestyle.com/products"
                },
                {
                    "@type": "ListItem",
                    "position": 3,
                    "name": categoryName, 
                    "item": `https://www.izzlifestyle.com/products/${categoryName.toLowerCase()}`
                },
                {
                    "@type": "ListItem",
                    "position": 4,
                    "name": productName,
                    "item": `https://www.izzlifestyle.com/products/details/${slug}`
                }
            ]
        };
     
        let script;
        let shouldAppend = false;
     
        if (this.document.head.getElementsByClassName(className).length) {
            script = this.document.head.getElementsByClassName(className)[0];
        } else {
            script = this.document.createElement('script');
            shouldAppend = true;
        }
    
        script.setAttribute('class', className);
        script.type = 'application/ld+json';
        script.text = JSON.stringify(schema);
     
        if (shouldAppend) {
            this.document.head.appendChild(script);
        }
    }

    insertWebsiteSchema(className = 'schema-web-search'){
        this.removeStructuredData(className)
        var schema  = {
            "@context": "https://schema.org",
            "@type": "WebSite",
            "url": "https://www.izzlifestyle.com",
            "potentialAction": {
              "@type": "SearchAction",
              "target": "https://www.izzlifestyle.com/products?q={search_term}",
              "query-input": "required name=search_term"
            }
        }

        let script;
        let shouldAppend = false;
     
        if (this.document.head.getElementsByClassName(className).length) {
            script = this.document.head.getElementsByClassName(className)[0];
        } else {
            script = this.document.createElement('script');
            shouldAppend = true;
        }
    
        script.setAttribute('class', className);
        script.type = 'application/ld+json';
        script.text = JSON.stringify(schema);
     
        if (shouldAppend) {
            this.document.head.appendChild(script);
        }
    }

    insertSocialSchema(className = 'schema-social-link'){
        this.removeStructuredData(className)
        var schema  = {
            "@context": "https://schema.org",
            "@type": "Organization",
            "name": "IzzLifestyle",
            "url": "https://www.izzlifestyle.com",
            "sameAs": [
              "https://web.facebook.com/Izzlifestyle.easy",
              "https://www.instagram.com/izzlifestyle",
              "https://www.instagram.com/izz_lifestyle"
            ],
            
        }

        let script;
        let shouldAppend = false;
     
        if (this.document.head.getElementsByClassName(className).length) {
            script = this.document.head.getElementsByClassName(className)[0];
        } else {
            script = this.document.createElement('script');
            shouldAppend = true;
        }
    
        script.setAttribute('class', className);
        script.type = 'application/ld+json';
        script.text = JSON.stringify(schema);
     
        if (shouldAppend) {
            this.document.head.appendChild(script);
        }
    }

    insertFAQSchema(className = 'schema-web-faq'){
        this.removeStructuredData(className)
        var schema  = {
            "@context": "https://schema.org",
            "@type": "FAQPage",
            "mainEntity": [
              {
                "@type": "Question",
                "name": "What is the return policy for this product?",
                "acceptedAnswer": {
                  "@type": "Answer",
                  "text": "We offer a 7-day return policy on all products with free returns."
                }
              },
              {
                "@type": "Question",
                "name": "How long will shipping take?",
                "acceptedAnswer": {
                  "@type": "Answer",
                  "text": "Shipping takes 3-10 business days for US orders and 7-10 business days for Malaysia."
                }
              },
              {
                "@type": "Question",
                "name": "How can I shop on Izzlifestyle?",
                "acceptedAnswer": {
                  "@type": "Answer",
                  "text": "To shop on Izzlifestyle, simply browse through our categories, select the items you want to purchase, and add them to your cart. Once you're ready, proceed to checkout to complete your order using secure payment methods."
                }
              },
              {
                "@type": "Question",
                "name": "Do you offer international shipping?",
                "acceptedAnswer": {
                  "@type": "Answer",
                  "text": "Yes, Izzlifestyle offers international shipping to many countries worldwide. We have a reliable logistics network that enables us to deliver our products to various international destinations. During the checkout process, you can select your country for shipping options and applicable shipping fees."
                }
              },
              {
                "@type": "Question",
                "name": "Can I track my order?",
                "acceptedAnswer": {
                  "@type": "Answer",
                  "text": "Yes, Izzlifestyle provides order tracking services. Once your order is shipped, we will provide you with a tracking number and instructions on how to track your package. You can use this information to monitor the progress of your shipment and ensure its safe and timely arrival."
                }
              },
            ]
        }

        let script;
        let shouldAppend = false;
     
        if (this.document.head.getElementsByClassName(className).length) {
            script = this.document.head.getElementsByClassName(className)[0];
        } else {
            script = this.document.createElement('script');
            shouldAppend = true;
        }
    
        script.setAttribute('class', className);
        script.type = 'application/ld+json';
        script.text = JSON.stringify(schema);
     
        if (shouldAppend) {
            this.document.head.appendChild(script);
        }
    }

    createLinkForCanonicalURL(url?:string) 
    {
        const canURL = url == undefined ? this.document.URL : url;
        let link; 
        let shouldAppend = false;
        if (this.document.head.getElementsByClassName('canon-link').length) {
            link = this.document.head.getElementsByClassName('canon-link')[0];
        } else {
            link = this.document.createElement('link');
            shouldAppend = true;
        }

        link.setAttribute('rel', 'canonical');
        link.setAttribute('class', 'canon-link');
        link.setAttribute('href', canURL);
        if(shouldAppend){ 
            this.document.head.appendChild(link);
        }  
    }

    getPriceValidUntil(): string {
        try{
            const currentDate = new Date(); 
            currentDate.setMonth(currentDate.getMonth() + 3);  
    
            const year = currentDate.getFullYear();
            const month = (currentDate.getMonth() + 1).toString().padStart(2, '0'); 
            const day = currentDate.getDate().toString().padStart(2, '0');
        
            return `${year}-${month}-${day}`;
        }
        catch{
            const currentDate = new Date(); 
            currentDate.setMonth(currentDate.getMonth() + 3);  
            return null;
        } 
    }
}
