<div class="product-card" [ngClass]="{
    'product-card--layout--grid product-card--size--sm': layout === 'grid-sm',
    'product-card--layout--grid product-card--size--nl': layout === 'grid-nl',
    'product-card--layout--grid product-card--size--lg': layout === 'grid-lg',
    'product-card--layout--list':                        layout === 'list',
    'product-card--layout--horizontal':                  layout === 'horizontal'
}">
    <button title="Quick View" class="product-card__quickview" type="button" appClick (click)="showQuickview()" [ngClass]="{'product-card__quickview--preload': showingQuickview}">
        <app-icon name="quickview-16" size="16"></app-icon>
        <span class="fake-svg-icon"></span>
    </button>

    <div *ngIf="product.Badges?.length" class="product-card__badges-list" style="display: flex; flex-direction: row; flex-wrap: wrap;"> 
        <div *ngIf="product.Discount > 0" class="product-card__badge product-card__badge--sale" style="font-size: 14px;" >-{{product.Discount}}%</div>
        <div *ngIf="product.Badges.includes('sale')" class="product-card__badge product-card__badge--sale">SALE</div>
        <div *ngIf="product.Badges.includes('hot')" class="product-card__badge product-card__badge--hot">Hot</div>
        <div *ngIf="product.Badges.includes('toprated')" class="product-card__badge product-card__badge--new">Rated</div>
    </div>

    <div *ngIf="!product.Badges?.length" class="product-card__badges-list" style="display: flex; flex-direction: row; flex-wrap: wrap;">
        <div *ngIf="product.Discount > 0" class="product-card__badge product-card__badge--sale" style="font-size: 14px;">-{{product.Discount}}%</div>
        <!-- <div *ngIf="product.Discount >= sellAt" class="product-card__badge product-card__badge--new">Sale</div> -->
    </div>

    <div class="product-card__image product-image">
        <a appClick [routerLink]="root.product(product)" *ngIf="product.Image" class="product-image__body">
          
            <picture>
                <source type="image/webp"  [srcset]="product.Image2">  
                <img loading="lazy" class="product-image__img" [src]="product.Image2" [alt]="product.Name">
            </picture>  
        </a>
    </div>
    <div class="product-card__info">
        <div class="product-card__name" [ngClass]="{'slider-class': isSlider}">
            <a appClick [routerLink]="root.product(product)">{{ product.Name }}</a>
        </div>
        <div class="product-card__rating" style="margin-left: -3px;">
            <app-rating [value]="product.Rating"></app-rating>
            <div class="product-card__rating-legend">
                Ratings
            </div>
        </div>
        <ul *ngIf="featuredAttributes.length" class="product-card__features-list">
            <li *ngFor="let attribute of featuredAttributes">
                {{ attribute.name }}:
                <ng-container *ngFor="let value of attribute.values; let last = last">
                    {{ value.name }}<ng-container *ngIf="!last">, </ng-container>
                </ng-container>
            </li>
        </ul>
    </div>
    <div class="product-card__actions">
        <div class="product-card__availability">
            Availability: 
           <span *ngIf="product.AvailableQuantity > 0" class="text-success">In Stock</span>
           <span *ngIf="product.AvailableQuantity < 1" class="text-danger">Out Of Stock</span>
        </div>
        <div class="product-card__prices">
            <ng-container *ngIf="product.RegularPrice > 0 && product.Price > 0">
                <span class="prod-price" [class.prod-price-max]="product.Discount >= sellAt">{{ product.Price|currencyFormat }}</span>{{ ' ' }}
                <span class="product-card__old-price" style="margin-left: 6px;">{{ product.RegularPrice|currencyFormat }}</span>
            </ng-container> 

            <ng-container *ngIf="product.Price > 0 &&  product.RegularPrice <= 0">
                <span class="prod-price">{{ product.Price|currencyFormat }}</span>
            </ng-container>

            <ng-container *ngIf="product.Price < 1">
                Upcoming
            </ng-container>

            <p class="brnd-p" *ngIf="!hideBrand">
                <app-icon *ngIf="product.IsFreeShipping" name="fi-free-delivery-48" size="26" style="fill: #47991f;" tooltip="Free Shipping"></app-icon>
                <span  *ngIf="product.IsFreeShipping" class="text-secondary">Shipping</span>
                <a *ngIf="!product.IsFreeShipping" [routerLink]="root.brandWithCat(product.BrandSlug, product.CategorySlug)">{{product.BrandName}}</a>
            </p>
        </div>
        <div class="product-card__buttons">  
            <button class="btn btn-primary product-card__addtocart" type="button" appClick (click)="addToCart()" [ngClass]="{'btn-loading': addingToCart}">
                Add To Cart
            </button> 
 
            <button class="btn btn-secondary product-card__addtocart product-card__addtocart--list" type="button" appClick (click)="addToCompare()" [ngClass]="{'btn-loading': addingToCompare}">Add To Compare</button>
            <button title="Add To Wishlist" class="btn btn-light btn-svg-icon btn-svg-icon--fake-svg product-card__wishlist" type="button" appClick (click)="addToWishlist()" [ngClass]="{'btn-loading': addingToWishlist}">
                <app-icon name="wishlist-16" size="16"></app-icon>
                <span class="fake-svg-icon fake-svg-icon--wishlist-16"></span>
            </button>
            <button title="Add To Compare" class="btn btn-light btn-svg-icon btn-svg-icon--fake-svg product-card__compare" type="button" appClick (click)="showQuickview()" [ngClass]="{'btn-loading': showingQuickview}">
                <app-icon name="share_fill1" size="16"></app-icon>
                <span class="fake-svg-icon fake-svg-icon--compare-16"></span>
            </button>
        </div>
    </div>
</div>
