import { Component, OnInit } from '@angular/core';
import { merge, Observable, Subject } from 'rxjs';
import { takeUntil, tap } from 'rxjs/operators';
import { ShopService } from 'src/app/shared/api/shop.service';
import { BlockHeaderGroup } from 'src/app/shared/interfaces/block-header-group';
import { OnlineProduct } from 'src/app/shared/interfaces/product';

interface ProductsCarouselGroup extends BlockHeaderGroup {
	products$: Observable<OnlineProduct[]>;
}

interface ProductsCarouselData {
	abort$: Subject<void>;
	loading: boolean;
	products: OnlineProduct[];
	groups: ProductsCarouselGroup[];
} 

@Component({
	selector: 'app-block-recent-products',
	templateUrl: './block-recent-products.component.html',
	styleUrls: ['./block-recent-products.component.scss']
})
export class BlockRecentProductsComponent implements OnInit {
	recentProducts: ProductsCarouselData;
	destroy$: Subject<void> = new Subject<void>();

	constructor(private shop:ShopService) { }

	ngOnInit(): void {
		this.recentProducts = { 
            abort$: new Subject<void>(),
            loading: false,
            products: [],
            groups: [
                {
                    name: 'Recent',
                    current: true,
                    products$: this.shop.getRecentViews(),
                }, 
                {
                    name: 'Deals',
                    current: false,
                    products$: this.shop.getFeaturedProducts('home-decor', 10),
                }
            ],
        };
        this.groupChange(this.recentProducts, this.recentProducts.groups[0]);
	}

	groupChange(carousel: ProductsCarouselData, group: BlockHeaderGroup): void {
		carousel.loading = true;
		carousel.abort$.next();

		(group as ProductsCarouselGroup).products$.pipe(
			tap(() => carousel.loading = false),
			takeUntil(merge(this.destroy$, carousel.abort$)),
		).subscribe(x => { carousel.products = x; });
	}
}
