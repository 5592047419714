<div class="product product--layout--{{ layout }}" *ngIf="product">
    <div class="product__content">
        <!-- .product__gallery -->
        <div class="product__gallery">
            <app-product-gallery [productName]="product.Name" [productLayout]="layout" [images]="selectedImages ? selectedImages : []"></app-product-gallery>
        </div>
        <!-- .product__gallery / end -->
        <!-- .product__info -->
        <div class="product__info">
            <div class="product__wishlist-compare">
                <button type="button" class="btn btn-sm btn-light btn-svg-icon" data-toggle="tooltip" data-placement="right" title="Wishlist"
                        appClick (click)="addToWishlist()"
                        [ngClass]="{'btn-loading': addingToWishlist}">
                    <app-icon name="wishlist-16" size="16"></app-icon>
                </button>
                <button type="button" class="btn btn-sm btn-light btn-svg-icon" data-toggle="tooltip" data-placement="right" title="Compare"
                        appClick (click)="addToCompare()"
                        [ngClass]="{'btn-loading': addingToCompare}">
                    <app-icon name="compare-16" size="16"></app-icon>
                </button>
            </div>

            <h1 class="product__name">{{ product.Name }}</h1>   

            <div class="product__rating"> 
                <div class="stock">
                    <span *ngIf="product.AvailableQuantity > 0"><span class="text-success">In Stock</span></span>
                    <span *ngIf="product.AvailableQuantity < 1"><span class="text-danger">Out Of Stock</span></span>
                </div>
                <div class="product__rating-stars" appClick (click)="$event.preventDefault(); scroll('prod-des-tab')">
                    <app-rating [value]="product.Rating"></app-rating>
                </div>
                <div class="product__rating-legend" style="margin-left: -8px; margin-top: -1px;">
                    <a href="javascript(void)" (click)="$event.preventDefault(); scroll('prod-des-tab')"
                     appClick>({{product.ReviewCount}})</a><span>/</span><a href="javascript(void)" (click)="$event.preventDefault(); scroll('prod-des-tab')" appClick>Write A Review</a>
                </div> 
            </div>  

            <ul class="product__meta"> 
                <!-- <li class="product__meta-availability" *ngIf="product.AvailableQuantity > 0"><span class="text-success">In Stock</span></li>
                <li class="product__meta-availability" *ngIf="product.AvailableQuantity < 1"><span class="text-danger">Out Of Stock</span></li> -->
                <li>Brand: <a [href]="root.brand(product.BrandSlug)|absoluteUrl:'angular'" appClick>{{product.BrandName}}</a></li>
                <li>Category: <a [href]="root.categoryBySlug(product.CategorySlug)|absoluteUrl:'angular'" appClick>{{toTitleCase(product.CategorySlug)}}</a></li> 
            </ul> 
        </div>
        <!-- .product__info / end -->
        <!-- .product__sidebar -->
        <div class="product__sidebar">
            <div class="product__availability">
               <!-- <span class="text-success">In Stock</span> -->
            </div>

            <div class="product__prices">
                <!-- <ng-container>
                    {{selectedPriceOption?.Price|currencyFormat }}
                </ng-container> -->
                <ng-container *ngIf="product.RegularPrice > 0 && product.Price > 0">
                    <span class="prod-price" [class.prod-price-max]="selectedPriceOption.Discount >= sellAt">{{ selectedPriceOption.Price|currencyFormat }}</span>{{ ' ' }}
                    <span class="product-card__old-price" style="margin-left: 6px; font-size: 14px;">{{ selectedPriceOption.RegularPrice|currencyFormat }}</span>
                    <span *ngIf="selectedPriceOption.Discount > 0" class="product-card__badge product-card__badge--sale" style="font-size: 14px; margin-left: 8px;" >-{{selectedPriceOption.Discount}}% Off</span>
                </ng-container> 
    
                <ng-container *ngIf="product.Price > 0 && product.RegularPrice <= 0">
                    <span class="prod-price">{{ product.Price|currencyFormat }}</span>
                </ng-container>
    
            </div>

            <div>
                <div style="background: linear-gradient(to bottom, #d4f0ff, #f9fdff); padding: 6px;
                margin-bottom: 15px;  font-weight: 500; border-radius: 8px; font-size: 15px;"> 
                    <div>
                        <div style="display: flex; align-items: center; justify-content: space-between;">
                                <div>
                                    Free Shipping
                                </div> 
                                <app-icon name="fi-free-delivery-48" size="28"></app-icon> 
                        </div>
                        <div>
                            <span *ngIf="haveSameMonth(dStartDate, dEndDate)"><span style="font-weight: lighter;" class="text-secondary">Delivery </span> <span *ngIf="dStartDate != null">{{dStartDate | date:'d'}}</span> - {{dEndDate | date:'d MMM'}}</span>
                            <span *ngIf="!haveSameMonth(dStartDate, dEndDate)"><span style="font-weight: lighter;" class="text-secondary">Delivery </span> <span *ngIf="dStartDate != null">{{dStartDate | date:'d MMM'}}</span> - {{dEndDate | date:'d MMM'}}</span>                            
                        </div>
                    </div> 

                    <div style="margin-top: 6px; display: flex; gap: 2px; justify-content: center; font-weight: lighter; font-size: 13px;">
                        <span>
                            <app-icon name="verified" size="24"></app-icon>
                        </span>
                        Safe payments: We do not share your personal details with any third parties without your consent.
                        Secure personal details: We protect your privacy and keep your personal details safe and secure.
                    </div>
                    <div style="display: flex; align-items: center; margin-top: 6px;" *ngIf="product.ReturnsInDay > 0">  
                        <app-icon name="updated" size="23" style="fill:#49adf4"></app-icon> 
                        <div style="margin-left: 3px;">{{product.ReturnsInDay}} Days<span class="text-secondary" style="font-weight: lighter;"> easy return</span> </div>
                    </div>
                </div> 
            </div>
            <!-- .product__options -->
            <form class="product__options">
                <div class="form-group product__option" *ngIf="product.OptionList?.length > 0">
                    <label *ngIf="product.OptionList?.length > 1" class="product__option-label">Variant <span>: {{selectedOption.option}}</span></label>
                    <div class="input-radio-color"> 
                        <div class="input-radio-color__list"> 
                            <label class="input-radio-color__item option-select" *ngFor = "let c of product.OptionList" for="Variant"
                                [style.color]="'#3377ff'" [style.background]="'url(' + getWebpImageUrl(c.items[0].Image) + ')'"
                                (click)="$event.preventDefault(); optionChoose(c)">
                                <input type="radio" name="Variant" [value]="c" [(ngModel)]="selectedOption">
                                <span style="height: 0; width: 0;"></span>
                            </label> 
                            <label style="margin-left: 12px;margin-top: 32px;" *ngIf="product.OptionList?.length < 2" class="product__option-label">Variant <span>: {{selectedOption.option}}</span></label>
                        </div>
                    </div>
                </div>
                <div class="form-group product__option" *ngIf="selectedOption?.items.length > 0 && selectedOption?.items[0].Size">
                    <label class="product__option-label">Size</label>
                    <div class="input-radio-label">
                        <div class="input-radio-label__list">
                            <label *ngFor = "let size of selectedOption.items" (click)="$event.preventDefault();sizeChoose(size)">
                                <input type="radio" name="material" [value]="size" [(ngModel)]="selectedPriceOption">
                                <span>{{size.Size}}</span>
                            </label> 
                        </div>
                    </div>
                </div>
                <div class="form-group product__option" *ngIf="product.SizeList?.length > 0">
                    <label class="product__option-label">Variations</label>
                    <div class="input-radio-label">
                        <div class="input-radio-label__list">
                            <label *ngFor="let vari of product.SizeList">
                                <input type="radio" name="material">
                                <span>{{vari?.Price|currencyFormat }} - {{vari.Text}}</span>
                            </label> 
                        </div>
                    </div>
                </div>
                <div class="form-group product__option">
                    <label class="product__option-label">Quantity</label>
                    <div class="product__actions">
                        <div class="product__actions-item">
                            <app-input-number aria-label="Quantity" class="product__quantity" size="lg" [min]="1" [formControl]="quantity"></app-input-number>
                        </div>
                        <div class="product__actions-item product__actions-item--addtocart">
                            <button type="button" class="btn btn-primary btn-lg"
                                    [ngClass]="{'btn-loading': addingToCart}"
                                    appClick (click)="addToCart()">Add to cart
                            </button> 
                        </div>
                        <div class="product__actions-item product__actions-item--wishlist">
                            <button type="button" class="btn btn-secondary btn-svg-icon btn-lg" data-toggle="tooltip" title="Wishlist"
                                    [ngClass]="{'btn-loading': addingToWishlist}"
                                    appClick (click)="addToWishlist()">
                                <app-icon name="wishlist-16" size="16"></app-icon>
                            </button>
                        </div>
                        <div class="product__actions-item product__actions-item--compare">
                            <button type="button" class="btn btn-secondary btn-svg-icon btn-lg" data-toggle="tooltip" title="Compare"
                                    [ngClass]="{'btn-loading': addingToCompare}"
                                    appClick (click)="addToCompare()">
                                <app-icon name="compare-16" size="16"></app-icon>
                            </button>
                        </div> 
                    </div>  
                </div>  
            </form>
            <!-- .product__options / end -->
        </div>
        <!-- .product__end -->
        <div class="product__footer">  
            <p [innerHtml]="product.Description">

            </p>   
            <ul class="product__features" style="display: block;"> 
                <li class="text-secondary" *ngIf="product.Model">Model: {{product.Model}}</li> 
                <li class="text-secondary">SKU: {{product.SKU}}</li>
            </ul>

            <div style="display: flex; align-items: center;" *ngIf="product.PaymentType == 1">  
                <app-icon name="fi-payment-security-48" size="28" style="fill: #47991f;"></app-icon> 
                <div style="margin-left: 8px;">Cash on delivery</div>
            </div>
            <!-- <div style="display: flex; align-items: center;" *ngIf="product.ReturnsInDay < 1">  
                <app-icon name="fi-free-delivery-48" size="28" style="fill: #47991f;"></app-icon> 
                <div style="margin-left: 8px;">Returns not possible</div>
            </div> -->
            <!-- <div style="display: flex; align-items: center;" *ngIf="product.ReturnsInDay > 0">  
                <app-icon name="fi-free-delivery-48" size="28" style="fill: #47991f;"></app-icon> 
                <div style="margin-left: 8px;">{{product.ReturnsInDay}} days easy return</div>
            </div> -->
            <div style="margin-top: 12px;">
                <app-share-buttons class="product__share-buttons shr-btns" 
                    [buttons]="['facebook', {type: 'twitter', label: 'Tweet'}, 'whatsapp']"
                    [pageUrl]="root.product(product)|absoluteUrl:'angular'"
                    [pageTitle]="product.Name"
                    [pageImage]="product.Images[0]|absoluteUrl:'html'">
                </app-share-buttons> 
            </div> 
        </div>
    </div>
</div>
