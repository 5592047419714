<div class="mobilemenu" [ngClass]="{'mobilemenu--open': isOpen}">
    <div class="mobilemenu__backdrop" (click)="mobilemenu.close()"></div>
    <div class="mobilemenu__body">
        <div class="mobilemenu__header">
            <div class="mobilemenu__title">
                <div class="topbar__item" style="margin-top: 8px;">
                    <div><span style="max-width: 26px; max-height: 18px;" class="flag flag-{{countryInfo?.CountryCode?.toLowerCase()}}"></span></div>
                </div>
            </div> 
            
            <div class="topbar__item" style="margin-top: 2px;">
               <span style="font-size: 13px; color: gray;" class="topbar__item-value">{{ currencyService.options.code }}({{currencyService.options.display}})</span> 
            </div>

            <div class="topbar__item">
                <div class="topbar-dropdown" appDropdown="topbar-dropdown--opened" #languageDropdown="appDropdown"> 
                    <button class="topbar-dropdown__btn" type="button" (click)="languageDropdown.toggle()">
                        <span class="topbar__item-value">EN</span>
                        <app-icon name="arrow-rounded-down-7x5" size="7x5"></app-icon>
                    </button>

                    <div class="topbar-dropdown__body">
                        <div class="menu menu--layout--topbar menu--with-icons">
                            <ul class="menu__list">
                                <li
                                    class="menu__item"
                                    *ngFor="let language of languages"
                                    (mouseover)="menuItem.classList.add('menu__item--hover')"
                                    (mouseleave)="menuItem.classList.remove('menu__item--hover')"
                                    #menuItem
                                >
                                    <button class="menu__item-link" type="button" (click)="languageDropdown.close();">
                                        <span class="menu__item-icon">
                                            <!-- <img [src]="'assets/images/languages/'+language.image+'.png'"
                                                 [srcset]="'assets/images/languages/'+language.image+'.png 1x,' +
                                                           'assets/images/languages/'+language.image+'@2x.png 2x'" alt=""> -->
                                        </span>
                                        {{ language.name }}
                                    </button>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div> 

            <button type="button" class="mobilemenu__close" (click)="mobilemenu.close()">
                <app-icon name="cross-20" size="20"></app-icon>
            </button>
        </div>
        <div class="mobilemenu__content">
            <app-mobile-links [links]="links" (itemClick)="onItemClick($event)"></app-mobile-links>

            <div style="margin-top: 10px;" class="account-menu__divider"></div>
            <app-block-products-carousel
                header=""
                layout="horizontal" 
                [hideHeader] = "true"
                [hideBrand] = "true"
                [rows]="2"
                [loading]="featuredProducts.loading"
                [products]="featuredProducts.products"
                [groups]="featuredProducts.groups"
                (groupChange)="groupChange(featuredProducts, $event)">
            </app-block-products-carousel>  
        </div>
 
    </div>
</div>
