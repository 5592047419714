<!-- .block-slideshow -->
<div class="block-slideshow block" *ngIf="!isServer"
    [ngClass]="{'block-slideshow--layout--full': !withDepartments, 'block-slideshow--layout--with-departments': withDepartments}"
    [style.background]="slideBackgroundColor" style="transition: 0.5s;">
    <div class="container">
        <div class="row">
            <div *ngIf="withDepartments" class="col-3 d-md-block d-lg-block d-none" appDepartmentsArea style="max-width: 270px;"></div>
            <div class="col-12" [ngClass]="{'col-lg-12': !withDepartments, 'col-lg-9': withDepartments}">
                <div class="block-slideshow__body">
                    <owl-carousel-o *ngIf="slides?.length"  [options]="options" appOwlPreventClick (changed)="caroChanged($event)" style="width: 1014px;">
                        <ng-template *ngFor="let slide of slides" carouselSlide>
                            <a class="block-slideshow__slide" [routerLink]="slide.link" appClick>
                                <img fetchpriority="high" class="block-slideshow__slide-image block-slideshow__slide-image--desktop"
                                    [src]="withDepartments ? slide.image_classic : slide.image_full" [alt]="slide.title"/>
                                <img fetchpriority="high" class="block-slideshow__slide-image block-slideshow__slide-image--mobile" 
                                    [src]="slide.image_mobile" 
                                    [alt]="slide.title"/>
                                <!-- <div class="block-slideshow__slide-content" [style.color]="slide.iswhite ? '#fff' : ''">
                                    <h1 style="max-width: 90%;" class="block-slideshow__slide-title" [innerHTML]="sanitizer.bypassSecurityTrustHtml(slide.title)"></h1>
                                    <h2 class="block-slideshow__slide-text" [innerHTML]="sanitizer.bypassSecurityTrustHtml(slide.text)" style="font-size: 16px; font-weight: normal;"></h2>
                                    <div class="block-slideshow__slide-button">
                                        <span class="btn btn-primary">Buy Now</span>
                                    </div> 
                                </div> -->
                            </a>
                        </ng-template>
                    </owl-carousel-o>
                </div>
            </div>
        </div>
    </div>
</div>
<!-- .block-slideshow / end -->
<div class="block-slideshow block" *ngIf="isServer"
    [ngClass]="{'block-slideshow--layout--full': !withDepartments, 'block-slideshow--layout--with-departments': withDepartments}"
    [style.background]="slideBackgroundColor" style="transition: 0.5s;">
    <div class="container">
        <div class="row">
            <div *ngIf="withDepartments" class="col-3 d-md-block d-lg-block d-none" appDepartmentsArea style="max-width: 270px;"></div>
            <div class="col-12" [ngClass]="{'col-lg-12': !withDepartments, 'col-lg-9': withDepartments}">
                <div class="block-slideshow__body">
                    <a class="block-slideshow__slide" [routerLink]="slideOne?.link" appClick>
                        <img fetchpriority="high" class="block-slideshow__slide-image block-slideshow__slide-image--desktop"
                            [src]="withDepartments ? slideOne?.image_classic : slideOne?.image_full" [alt]="slideOne?.title"/>
                        <img fetchpriority="high" class="block-slideshow__slide-image block-slideshow__slide-image--mobile" 
                            [src]="slideOne?.image_mobile" 
                            [alt]="slideOne?.title"/> 
                            <div class="block-slideshow__slide-content" [style.color]="slideOne?.iswhite ? '#fff' : ''">
                                <h1 style="max-width: 90%;" class="block-slideshow__slide-title" [innerHTML]="sanitizer.bypassSecurityTrustHtml(slideOne?.title)"></h1>
                                <h2 class="block-slideshow__slide-text" [innerHTML]="sanitizer.bypassSecurityTrustHtml(slideOne?.text)" style="font-size: 16px; font-weight: normal;"></h2>
                                <div class="block-slideshow__slide-button">
                                    <span class="btn btn-primary">Buy Now</span>
                                </div> 
                            </div>
                    </a>
                </div>
            </div>
        </div>
    </div>
</div>

