import { Brand } from 'src/app/shared/interfaces/brand';
import { Category } from 'src/app/shared/interfaces/category';

 
export const top_categories: Category[] = [
    {
      "id": 2,
      "parentId": 1,
      "name": "Home & Living",
      "type": "products",
      "path": "",
      "image": "living-room",
      "items": 989,
      "parents": [],
      "children": [],
      "slug": "home-decor"
    },
    {
      "id": 12,
      "parentId": 24,
      "name": "Gadgets & Gears",
      "type": "products",
      "path": "",
      "image": "gadgets-2",
      "items": 462,
      "parents": [],
      "children": [],
      "slug": "gadget"
    },
    {
      "id": 150,
      "parentId": 1,
      "name": "Kids Buy",
      "type": "products",
      "path": "",
      "image": "toys-2",
      "items": 416,
      "parents": [],
      "children": [],
      "slug": "toys"
    },
    {
      "id": 151,
      "parentId": 30,
      "name": "Fashion & Lifestyle",
      "type": "products", 
      "image": "clothing-2",
      "items": 141,
      "parents": [],
      "children": [],
      "slug": "clothing", 
      "path": ""
    },
    {
      "id": 151,
      "parentId": 30,
      "name": "Luxury Jewellery",
      "type": "products", 
      "image": "jewelry",
      "items": 141,
      "parents": [],
      "children": [],
      "slug": "jewelery", 
      "path": ""
    },
    {
      "id": 151,
      "parentId": 30,
      "name": "Health & Fitness",
      "type": "products", 
      "image": "health",
      "items": 141,
      "parents": [],
      "children": [],
      "slug": "health-and-life", 
      "path": ""
    },
    {
      "id": 151,
      "parentId": 30,
      "name": "Stylish Car",
      "type": "products", 
      "image": "car",
      "items": 141,
      "parents": [],
      "children": [],
      "slug": "car-decor", 
      "path": ""
    },
    {
      "id": 151,
      "parentId": 30,
      "name": "Watches & More",
      "type": "products", 
      "image": "watch",
      "items": 141,
      "parents": [],
      "children": [],
      "slug": "jewelery-and-watches", 
      "path": ""
    }
  ];