<!-- .block-banner -->
<div class="block block-banner">
    <div class="container">
        <a [routerLink]="root.productBySlug('vivo-y21')" class="block-banner__body">
            <div class="block-banner__image block-banner__image--desktop">
                <img loading="lazy" style="max-width: 100%; height: auto;" src="assets/images/banners/custom-tshirt.png" alt="Top-Quality Quality Home, Lifestyle, Toy Products & Gadgest at Best Price with Free Shipping"/>
            </div>
            <div class="block-banner__image block-banner__image--mobile">
                <img loading="lazy" style="max-width: 100%; height:auto;" src="assets/images/banners/mobile-banner-1-min.png" alt="Top-Quality Quality Home, Lifestyle, Toy Products & Gadgest at Best Price with Free Shipping"/>
            </div> 
        </a>
    </div>
</div>
<!-- .block-banner / end -->
