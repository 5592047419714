import { Observable } from 'rxjs';
import { debounceTime } from 'rxjs/operators';

export function fromMatchMedia(query: string, ignoreInitial = true): Observable<MediaQueryList> {
    return new Observable<MediaQueryList>(observer => {
        const mediaQueryList = matchMedia(query);

        const onChange = () => observer.next(mediaQueryList);
 
        if (!ignoreInitial) {
            observer.next(mediaQueryList);
        }

        const mediaQueryObservable = new Observable<void>((subObserver) => {
            const listener = () => subObserver.next();
            
            if (mediaQueryList.addEventListener) {
                mediaQueryList.addEventListener('change', listener, { passive: true });
                return () => mediaQueryList.removeEventListener('change', listener);
            } else {
                mediaQueryList.addListener(listener);
                return () => mediaQueryList.removeListener(listener);
            }
        }).pipe(
            debounceTime(300)
        );

        mediaQueryObservable.subscribe(() => onChange()); // Trigger the onChange after debounce

        // Cleanup when unsubscribed
        return () => mediaQueryList.removeEventListener('change', onChange);
    });
}