import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, Router } from '@angular/router';
import { Injectable } from '@angular/core'; 
import { take, map } from 'rxjs/operators';
import { StorageService } from '../services/storage.service';
  
@Injectable()
export class AuthGuard implements CanActivate 
{
    constructor(private router: Router, private storage:StorageService) {

    }

    canActivate(route: ActivatedRouteSnapshot,
        state: RouterStateSnapshot) 
        { 
            try 
            {   
                if (this.storage.getItem("cul_culture_tm_stmp_temp") === undefined || this.storage.getItem("cul_culture_tm_stmp_temp") === null)
                { 
                    this.router.navigateByUrl('/account/login').then();
                }
 
                return true;
            }
            catch 
            { 
                return false;
            }
    } 
}
