<div *ngIf="!isServer"
    class="product-gallery__featured"
    redZoomClass="product-red-zoom product-red-zoom--layout--{{ productLayout }}"
    [redZoom]="currentItem?.image"
    [redZoomThumb]="currentItem?.image"
    [redZoomLazy]="true"
>
    <div class="product-gallery__featured-inset">
        <button *ngIf="productLayout !== 'quickview'" class="product-gallery__zoom" (click)="openPhotoSwipe(currentItem)" title="Zoom">
            <app-icon name="zoom-in-24" size="24"></app-icon>
        </button>
        <owl-carousel-o [options]="carouselOptions" (changed)="featuredCarouselTranslated($event)" appOwlPreventClick #featuredCarousel>
            <!--suppress AngularUndefinedBinding -->
            <ng-template *ngFor="let item of items" carouselSlide [id]="item.id">
                <div class="product-image product-image--location--gallery">
                    <a
                        appClick
                        class="product-image__body"
                        rel="noopener noreferrer"
                        target="_blank"
                        [href]="getWebpImageUrl(item.image)"
                        (click)="onFeaturedImageClick($event, item);"> 
                        <img loading="lazy" class="product-image__img" [src]="getWebpImageUrl(item.image)" [alt]="productName" #imageElement>
                    </a>
                </div>
            </ng-template>
        </owl-carousel-o>
    </div>
</div>  

<div class="product-gallery__featured-inset" *ngIf="isServer"> 
    <div class="product-image product-image--location--gallery">
        <a
            appClick
            class="product-image__body"
            rel="noopener noreferrer"
            target="_blank"
            [href]="getWebpImageUrl(currentItem?.image)"> 
            <img fetchpriority="high" class="product-image__img" [src]="getWebpImageUrl(currentItem.image)" [alt]="productName" #imageElement>
        </a>
    </div>
</div>  

<div class="product-gallery__carousel">
    <owl-carousel-o
        appOwlPreventClick
        [options]="thumbnailsCarouselOptions"
        [appFakeSlides]="items.length"
        #fakeSlides="appFakeSlides"
        #thumbnailsCarousel>
        <!--suppress AngularUndefinedBinding -->
        <ng-template *ngFor="let item of items" carouselSlide [id]="item.id" >
            <div appClick
                class="product-image product-gallery__carousel-item"
                [class.product-gallery__carousel-item--active]="item === currentItem"
                (click)="onThumbnailImageClick(item)">
                <div class="product-image__body">
                    <img loading="lazy" class="product-image__img" [src]="getWebpImageUrl(item.image)" [alt]="productName" #imageElement> 
                </div>
            </div>
        </ng-template>
        <!--suppress AngularUndefinedBinding -->
        <ng-template *ngFor="let i of fakeSlides.slides" carouselSlide [id]="'fake-slide-'+i"></ng-template>
    </owl-carousel-o>
</div>
