import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { Brand } from '../interfaces/brand';
import { Category } from '../interfaces/category';

@Injectable({
  providedIn: 'root'
})
export class CategoryService 
{ 
  	constructor( private http: HttpClient) { }

	getAllParentCategory(): Observable<any> 
	{ 
        return this.http.get<any>(environment.apiUrl + `/api/Category/GetAllParentCategory`); 
    }

	getCategoriesByParentId(parentId:number): Observable<any> 
	{ 
        return this.http.get<any>(environment.apiUrl + `/api/Category/GetSubCategoryByParentId/${parentId}`); 
    } 

    getCategoryMenu(): Observable<any> 
	{ 
        return this.http.get<any>(environment.apiUrl + `/api/Category/GetCategoryMenuV2`); 
    }

    getCategory(slug: string): Observable<Category> 
    { 
        return this.http.get<any>(environment.apiUrl + `/api/Category/GetCategoryBySlug/${slug}`);
    }

    getBrandBySlug(slug:string): Observable<Brand> 
	{ 
        return this.http.get<any>(environment.apiUrl + `/api/Category/GetBrandBySlug/${slug}`); 
    }


    //#region BRAND CALLS
    getAllBrands(): Observable<any> 
	{ 
        return this.http.get<any>(environment.apiUrl + `/api/Category/getAllBrands`); 
    }

    getBrandsByCategory(catSlug:string): Observable<any> 
	{ 
        return this.http.get<any>(environment.apiUrl + `/api/Category/GetBrandsByCategory/${catSlug}`); 
    }
    //#endregion

    //#region BRAND CALLS
    getAllProductFeatureTemplates(): Observable<any> 
	{ 
        return this.http.get<any>(environment.apiUrl + `/api/Category/GetFetureTemplates`); 
    }

    getAIGeneratedText(text:string, maxLength:number = 200): Observable<string> 
	{ 
        return this.http.get<any>(environment.apiUrl + `/api/AI/GetAIGeneratedText?text=${text}&maxLength=${maxLength}`); 
    }
}
