<!-- .block-categories -->
<div class="block block--highlighted block-categories block-categories--layout--{{ layout }}">
    <div class="container">
        <app-block-header [header]="header"></app-block-header>

        <div class="block-categories__list">
            <div *ngFor="let category of categories" class="block-categories__item category-card category-card--layout--{{ layout }}">
                <div class="category-card__body">
                    <div class="category-card__image">
                        <a [routerLink]="root.category(category)">
                            <!-- <app-icon class="cat-ico" [name]="category.image" size="56"></app-icon>  -->
                             <img loading="lazy" [alt]="category.name" src="../../../../assets/images/categories/{{category.image}}.png" />
                        </a>
                    </div>
                    <div class="category-card__content">
                        <div class="category-card__name">
                            <a [routerLink]="root.category(category)">{{ category.name }}</a>
                        </div>
                        <ul class="category-card__links">
                            <li *ngFor="let category of category.children.slice(0, 5)">
                                <a [routerLink]="root.category(category)">{{ category.name }}</a>
                            </li>
                        </ul>
                        <!-- <div class="category-card__all">
                            <a [routerLink]="root.category(category)">Show All</a>
                        </div> -->
                        <div class="category-card__products">
                            {{ category.items }} Products
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
<!-- .block-categories / end -->
