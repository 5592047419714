import { Component, Input } from '@angular/core';

@Component({
    selector: 'app-rating',
    templateUrl: './rating.component.html',
    styleUrls: ['./rating.component.scss']
})
export class RatingComponent {
    icon:string ='no-star';

    @Input() set value(value: number) {
         
        if(value > 4){
            this.icon = '5-star'
        }
        else if(value > 3){
            this.icon = '4-star'
        }
        else if(value > 2){
            this.icon = '3-star'
        }
        else if(value > 1){
            this.icon = '2-star'
        }
        else if(value > 0){
            this.icon = '1-star'
        }
    }

    constructor() { }
}
