import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { Category } from '../interfaces/category';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { Post } from '../interfaces/post';
//import { getBlogCategoriesTree } from '../../../fake-server';

@Injectable({
    providedIn: 'root'
})
export class BlogService {
    baseUrl:string = environment.apiUrl; 
    
    constructor(
        private http: HttpClient,
    ) { }
 
    getCategories(cat:string = '', query = ''): Observable<Category[]> {
     
        const top_categories: Category[] = [
            {
              "id": 2,
              "parentId": 1,
              "name": "Review",
              "type": "blog",
              "path": "",
              "image": "mobile-phone",
              "items": 989,
              "parents": [],
              "children": [],
              "slug": "review"
            },
            {
                "id": 12,
                "parentId": 1,
                "name": "Tech Blog",
                "type": "blog",
                "path": "",
                "image": "fridge",
                "items": 428,
                "parents": [],
                "children": [],
                "slug": "blog"
            },
            {
              "id": 12,
              "parentId": 1,
              "name": "Updates",
              "type": "blog",
              "path": "",
              "image": "fridge",
              "items": 428,
              "parents": [],
              "children": [],
              "slug": "updates"
            }
          ];
        return of(top_categories);//getBlogCategoriesTree(parent ? parent.slug : null, depth);
    }

    getBlogDetail(slug:string):Observable<any>
    {
       return this.http.get<any>(this.baseUrl+`/api/Blog/GetBlog?slug=${slug}`);
    }

    getBlogList(cat:string = '', query = ''):Observable<any>
    {
        let posts: Post[] = [
          {
              title: 'Unlocking the World of Stylish Home Decor, Gifts, and Car Accessories: Your Guide to Easily Source Quality Products Online with IzzLifestyle',
              image: 'assets/images/posts/post-1.png',
              categories: ['Product Review'],
              date: 'November 25, 2022',
              slug:'unlocking-the-world-of-fashion-your-guide-to-easily-source-home-decor-and-toys-car-accessories-online-with-izzlifestyle',
              shortDescrip:"In today’s digital age, sourcing high-quality home decor, gifts, and car accessories is easier than ever. Thanks to online platforms like IzzLifestyle, finding the perfect items for your home, gifting needs, or car interior is just a few clicks away. In this blog, we’ll walk you through how to effortlessly explore and source the best products from IzzLifestyle, making your online shopping experience both seamless and enjoyable.",
              categorySlug:'review'
          },
          {
              title: 'Empower Your Lifestyle: How IzzLifestyle Enhances Your Home, Gifts, and Car Accessories Shopping Experience',
              image: 'assets/images/posts/post-2.png',
              categories: ['Product Review'],
              date: 'November 25, 2022',
              slug:'empower-lifestyle-with-izzlifestyle-your-brand-new-premium-quality-home-items-any-products-premium-best-price',
              shortDescrip:"In a fast-paced world where personalization and quality are key, IzzLifestyle stands out as the ultimate destination for discovering high-quality home decor, unique gifts, and stylish car accessories. Whether you're looking to revamp your living space, surprise a loved one with a thoughtful gift, or elevate your car's interior, IzzLifestyle offers an extensive selection of curated products that meet your lifestyle needs. In this blog, we’ll explore how IzzLifestyle is changing the game for shoppers who want convenience, quality, and style all in one place.",
              categorySlug:'review'
          }
      ];

      if(cat && cat !== '')
      {
        posts = posts.filter(p => p.categorySlug == cat)
      }
      return of(posts);
    }
}
