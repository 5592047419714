import { Component, OnDestroy } from '@angular/core';
import { FormControl, Validators } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { AccountService } from 'src/app/modules/account/account.service';

@Component({
    selector: 'app-footer-newsletter',
    templateUrl: './newsletter.component.html',
    styleUrls: ['./newsletter.component.scss']
})
export class NewsletterComponent implements OnDestroy{
    emailControl = new FormControl('', Validators.required);
    calling:boolean;
    destroy$:Subject<void> = new Subject();
    invalidEmail:boolean;
    constructor(private acSer:AccountService, private toastr:ToastrService) { }
    ngOnDestroy(): void {
        this.destroy$.next();
        this.destroy$.complete();
    }

    submit()
    {  
        this.emailControl.markAsTouched();
        var regexp = new RegExp(/^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/);
        var x =  regexp.test(this.emailControl.value); 
        if(x === false){
            this.invalidEmail = true;
            return
        }
        else{
            this.invalidEmail = false;
        }
        
        if(this.emailControl.hasError('required')){return}

        this.calling = true;
        this.acSer.subscribeRequest(this.emailControl.value).pipe(takeUntil(this.destroy$)).subscribe(res =>
        {
            if(res === 3){
                this.toastr.success("You are already our subscriber, Thank you!", "Subscriber",{
					timeOut: 7000, 
				});
                this.emailControl.reset();
            }
            else if(res === 1){
                this.toastr.success("You have successfully subscribed, Thank you.", "Subscription Successful",{
					timeOut: 8000, 
				});
                this.emailControl.reset();
            }
            else{
                this.toastr.error("Something went wrong, try again!", "Unsuccessful",{
					timeOut: 5000, 
				});
            }
            this.calling = false;
        }, error =>{
            this.toastr.error("Something went wrong, try again!", "Unsuccessful",{
                timeOut: 5000, 
            });
            this.calling = false;
        });
    }
}
