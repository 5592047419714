<ul class="share-buttons__list">
    <li *ngFor="let item of items" class="share-buttons__item share-buttons__item--type--{{ item.type }}"
        [class.share-buttons__item--without-label]="!(item.label && showLabels)">

        <a [attr.href]="item.url" target="_blank" appClick style="display: flex; align-items: center;">
            <app-icon style="fill: #fff;"  [name]="item.icon" size="16" *ngIf="item.icon && showIcons" class="share-buttons__item-icon"></app-icon>
            <span *ngIf="item.label && showLabels" class="share-buttons__item-label">{{ item.label }}</span>
        </a>
    </li>
</ul>
